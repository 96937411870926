export const newArrivals = [
  {
    _id: 7,
    productName: "R1 CHARGED CREATINE SNOW CONE",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "R1 Charged Creatine’s refreshing fruit flavors are built to support an enhanced workout with hydration, energy, and greater training capacity.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 25,
    img: "/images/creatin.png",
  },

  {
    _id: 10,
    productName: "BCAA LEAN ENERGY BLUE RAZ",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "BCAA LEAN ENERGY is our most complete lean body performance fuel for anytime use with 5g of the highest quality BCAAs per serving combined with CLA, Acetyl-L-Carnitine and Maca Powder for fat burning and Beta-Alanine, L-Alanine and Taurine and to enhance endurance, recovery and focus. BCAA LEAN ENERGY uses natural energizers and antioxidants from Green Coffee and Green Tea for energy and focus. And if you’re on a low-carb or Keto diet, BCAA LEAN ENERGY can help support fat utilization and help fight fatigue often felt when in a calorie deficit. Make BCAA LEAN ENERGY your fuel for anytime energy, fat burning and performance.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 15,
    img: "/images/bcaa.png",
  },
  {
    _id: 12,
    productName: "ISO100 HYDROLYZED WHEY",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "ISO100 is simply muscle-building fuel. If your goal is gaining muscle size and strength, then ISO100 is your perfect workout partner. Loaded with muscle-building amino acids, ISO100 can support even the most serious resistance-training programs.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 80.5,
    img: "/images/iso100.png",
  },
  {
    _id: 13,
    productName: "CLEAN MEAL SNICKERDOODLE",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "Never miss a meal again. Clean Meal™ is a quick and healthy meal replacement for people on the move. Scoop, shake, and, in just 60 seconds, you’ve got a complete meal packed with wholesome, whole-food nutrition: slow-burning carbs from Swedish oats and organic quinoa; organic coconut MCT oil; 21 vitamins and minerals from organic vegetables; and non-GMO whey protein isolate treated with ioWhey® technology for up to 123% better amino acid absorption*. Rich, creamy, and satisfying, reach for Clean Meal™ to replace up to two meals per day, or anytime you need a complete, nourishing meal on the go.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 50.5,
    img: "/images/cleanmeal.png",
  },
];

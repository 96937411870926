import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";

import axios from 'axios'

const Home = () => {
  
  // useEffect(()=>{
  //   axios.post("http://localhost:3001/isyerim/createPaymentlink").then((res)=>{
  //   console.log(res)
  //   })
  // },[])
  return (
    <div className="w-full mx-auto">
      <Banner />
      {/* <BannerBottom /> */}
      <div className="max-w-container mx-auto px-4">
        <Sale />
        <NewArrivals />
        {/* <BestSellers /> */}
        {/* <YearProduct />
        <SpecialOffers /> */}
      </div>
    </div>
  );
};

export default Home;

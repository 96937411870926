export const products =[
    {
      _id: 42,
      productName: "Hızlı Saç Uzatma Uçucu Yağı - Çay Ağacı Özü 10ml",
      ficheTech: [
        {
          label: "Çay ağacı özü ile hazırlanmış %100 doğal uçucu yağ."
        },
        {
          label: "Saç büyümesini desteklemeye yardımcı olabilir."
        },
        {
          label: "Antiseptik ve antifungal özellikler içerir."
        },
        {
          label: "Saç derisini temizler ve sağlıklı bir ortam sağlar."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti Fast Hair Growth Essential Oil with Tea Tree Extract is a premium oil designed to support rapid hair growth and maintain a healthy scalp. This 100% natural essential oil harnesses the powerful properties of tea tree extract, known for its antiseptic and antifungal benefits. It helps to cleanse the scalp, promote a healthy hair growth environment, and improve overall hair health. Ideal for those looking to enhance their hair care routine with a natural solution, Scuti Tea Tree Oil provides concentrated benefits for vibrant and strong hair.",
        tr: "Scuti Hızlı Saç Uzatma Uçucu Yağı, çay ağacı özü ile hazırlanmış yüksek kaliteli bir üründür. Saç büyümesini desteklemek ve sağlıklı bir saç derisi sağlamak için tasarlanmıştır. %100 doğal uçucu yağ, antiseptik ve antifungal özellikleri ile bilinen çay ağacı özünden faydalanır. Saç derisini temizlemeye, sağlıklı bir saç büyüme ortamı oluşturmaya ve genel saç sağlığını iyileştirmeye yardımcı olabilir. Doğal bir çözüm arayanlar için ideal bir seçenek olan Scuti Çay Ağacı Yağı, canlı ve güçlü saçlar için yoğun faydalar sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Çay ağacı özünden elde edilen %100 doğal uçucu yağ.",
      price: 375,
      img: 
        "/images/85.webp",
      ingredients: [
        "Çay Ağacı Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Saç bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek saç derisine masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Saç büyümesini destekler.",
        "Saç derisini temizler ve sağlıklı bir ortam sağlar.",
        "Antiseptik ve antifungal etkiler sunar."
      ]
    },
    {
      _id: 46,
      productName: "Adaçayı (Acı Elma) Uçucu Yağı 10 Ml %100 Saf Ve Doğal Ada Çayı Clary Sage Essential Oil",
      ficheTech: [
        {
          label: "%100 saf ve doğal adaçayı uçucu yağı."
        },
        {
          label: "Rahatlatıcı ve dengeleyici özelliklere sahiptir."
        },
        {
          label: "Kadın sağlığı ve hormon dengesine yardımcı olabilir."
        },
        {
          label: "Cilt ve saç bakımında kullanılabilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Clary Sage Essential Oil, derived from the Clary Sage plant, is renowned for its calming and balancing properties. This essential oil is ideal for promoting emotional well-being, supporting hormonal balance, and enhancing relaxation. It can be used in various applications including in skincare routines to soothe and balance the skin, and in hair care to improve hair health. Scuti Clary Sage Oil offers a natural and effective solution for those seeking to enhance their overall wellness with a pure and potent essential oil.",
        tr: "Scuti %100 Saf Adaçayı (Acı Elma) Uçucu Yağı, Adaçayı bitkisinden elde edilmiştir ve rahatlatıcı ve dengeleyici özellikleri ile tanınır. Bu uçucu yağ, duygusal iyilik hali sağlamada, hormonal dengeyi desteklemede ve gevşeme sağlama konusunda idealdir. Cilt bakım rutinlerinde kullanılarak cildin yatıştırılması ve dengelenmesine yardımcı olabilir; saç bakımında ise saç sağlığını iyileştirebilir. Scuti Adaçayı Yağı, saf ve etkili bir uçucu yağ ile genel sağlığı artırmak isteyenler için doğal ve güçlü bir çözüm sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Adaçayı bitkisinden elde edilen %100 saf ve doğal uçucu yağ.",
      price: 425,
      img: 
        "/images/89.webp",
      ingredients: [
        "Adaçayı Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Duygusal denge ve rahatlama sağlamak için birkaç damla buharlaştırıcıya eklenebilir. Cilt bakımında kullanılabilir ve saç sağlığını desteklemek için saç maskelerine eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Duygusal iyilik hali ve rahatlama sağlar.",
        "Hormonal dengeyi destekler.",
        "Cilt ve saç bakımında kullanılır."
      ]
    },
    {
      _id: 41,
      productName: "Ardıç Yağı - %100 Saf Ardıç Uçucu Yağı - 10 ml",
      ficheTech: [
        {
          label: "%100 saf ve doğal ardıç uçucu yağıdır."
        },
        {
          label: "Ardıç meyvelerinden elde edilmiştir."
        },
        {
          label: "Cilt ve saç bakımında kullanılabilir."
        },
        {
          label: "Anti-enflamatuar ve antiseptik özelliklere sahiptir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Juniper Berry Oil is an essential oil extracted from juniper berries, known for its natural and effective properties. This high-quality oil offers beneficial care for both skin and hair, with its powerful anti-inflammatory and antiseptic properties. Juniper berry oil is ideal for those looking to detoxify the skin, relieve minor aches and pains, and support overall wellness. Its refreshing aroma can also help to uplift the mood and promote relaxation. Perfect for incorporating into your personal care routine, Scuti Juniper Berry Oil delivers concentrated benefits in a pure and potent form.",
        tr: "Scuti %100 Saf Ardıç Yağı, ardıç meyvelerinden elde edilen yüksek kaliteli bir uçucu yağıdır. Doğal ve etkili özellikleri ile cilt ve saç bakımı için faydalı bir ürün sunar. Güçlü anti-enflamatuar ve antiseptik özellikleri sayesinde ciltte detoks etkisi sağlar, küçük ağrı ve rahatsızlıkları hafifletir ve genel sağlığı destekler. Ferahlatıcı aroması da ruh halinizi yükseltmeye ve rahatlamayı teşvik etmeye yardımcı olabilir. Kişisel bakım rutininize dahil edebileceğiniz Scuti Ardıç Yağı, saf ve etkili bir formda yoğun faydalar sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Yüksek kaliteli ardıç meyvelerinden elde edilmiş %100 saf uçucu yağ.",
      price: 425,
      img: 
        "/images/84.webp",
      ingredients: [
        "Ardıç Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Cilt ve saç bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Cilt detoksu ve rahatlama sağlar.",
        "Küçük ağrı ve rahatsızlıkları hafifletir.",
        "Anti-enflamatuar ve antiseptik etkiler sunar."
      ]
    },
    {
      _id: 39,
      productName: "Biberiye Yağı %100 Doğal Bitkisel Uçucu Yağ Rosemary Oil 10ml",
      ficheTech: [
        {
          label: "%100 doğal ve saf bitkisel uçucu yağdır."
        },
        {
          label: "Yüksek kalitede biberiye özleri içerir."
        },
        {
          label: "Saç ve cilt bakımında kullanıma uygundur."
        },
        {
          label: "Canlandırıcı ve iyileştirici özelliklere sahiptir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Natural Rosemary Oil is a premium essential oil renowned for its invigorating and therapeutic properties. This high-quality rosemary oil is designed to offer natural care for both skin and hair. With its revitalizing effects, it helps to stimulate hair growth, promote healthy scalp conditions, and enhance overall well-being. Additionally, rosemary oil is known for its ability to improve mental clarity and reduce stress. Ideal for those seeking a natural solution for their personal care routine, Scuti Rosemary Oil delivers the concentrated benefits of rosemary in a pure and potent form.",
        tr: "Scuti %100 Doğal Biberiye Yağı, canlandırıcı ve terapötik özellikleriyle tanınan yüksek kaliteli bir uçucu yağıdır. Bu yüksek kaliteli biberiye yağı, hem cilt hem de saç bakımı için doğal çözümler sunmak üzere tasarlanmıştır. Canlandırıcı etkileri sayesinde saç büyümesini destekler, sağlıklı saç derisi koşullarını teşvik eder ve genel refahı artırır. Ayrıca, biberiye yağı zihinsel netliği artırma ve stresi azaltma özelliği ile bilinir. Doğal bakım rutini arayanlar için ideal bir tercihtir ve biberiye bitkisinin yoğun faydalarını saf ve etkili bir formda sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Yüksek kaliteli biberiye özleri ile hazırlanmış %100 doğal uçucu yağ.",
      price: 425,
      img: 
        "/images/82.webp",
      ingredients: [
        "Biberiye Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Saç ve cilt bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Saç sağlığını destekler ve saç büyümesini teşvik eder.",
        "Ciltte canlandırıcı ve iyileştirici etkiler sağlar.",
        "Zihinsel netliği artırır ve stresi azaltır."
      ]
    },
    {
      _id: 35,
      productName: "Scuti Saf Organik Katkısız Dut Pekmezi 350 gr",
      ficheTech: [
        {
          label: "Yüksek lif içeriği sindirim sistemini destekler ve enerji sağlar."
        },
        {
          label: "Kalsiyum, demir, magnezyum, potasyum gibi mineraller ve A, B, C vitaminleri içerir."
        },
        {
          label: "Antioksidanlar bakımından zengindir, hücreleri korur ve genel sağlığa destek sağlar."
        },
        {
          label: "Doğal şeker içeriği ile enerji sağlar ve kan şekerinin dengelenmesine yardımcı olabilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti Saf Organik Katkısız Dut Pekmezi offers a healthy and natural sweetening option with its unique taste from nature. Made from carefully selected organic mulberries, this special molasses contains pure extract. It provides a healthy eating experience with its high nutritional values. Rich in fiber, it supports the digestive system and provides energy while offering a natural sweetener alternative. The molasses is also a rich source of minerals such as calcium, iron, magnesium, potassium, and vitamins A, B, and C, contributing to daily nutritional needs. Additionally, it is abundant in antioxidants that fight free radicals, protect cells, and support overall health. Its natural sugar content provides energy and can help balance blood sugar levels, making it a great choice for a healthy lifestyle. Ideal for those seeking a natural and nourishing sweetener. Follow the product label and usage recommendations for best results.",
        tr: "Scuti Saf Organik Katkısız Dut Pekmezi, doğanın eşsiz lezzetini sofralarınıza taşıyan sağlıklı bir tatlandırıcı sunar. Özenle seçilmiş organik dut meyvelerinden elde edilen bu özel pekmez, saf bir öz içerir. Yüksek besin değerleri ile sağlıklı bir beslenme deneyimi sunar. Yüksek lif içeriği sindirim sistemini destekler ve enerji sağlar, doğal şekerleri ile tatlandırıcı bir alternatif sunar. Dut pekmezi, kalsiyum, demir, magnezyum, potasyum gibi mineralleri ve A, B, C vitaminlerini içerir, vücudunuzun günlük vitamin ve mineral ihtiyaçlarına katkıda bulunabilir. Ayrıca, antioksidanlar bakımından zengindir ve serbest radikallerle mücadele ederek hücreleri korur ve genel sağlığa destek sağlar. Doğal şeker içeriği ile enerji sağlar ve kan şekerinin dengelenmesine yardımcı olabilir. Organik ve katkısız içeriği ile güvenle tüketilebilir, sağlıklı ve doğal bir tatlandırıcı arayanlar için ideal bir tercihtir. Ürün etiketi ve kullanım önerilerine göre tüketilmesi önemlidir."
      },
      cat: "Molasses",
      pdf: "",
      extraInfo: "Organik ve katkısız içerikler kullanılarak üretilmiştir.",
      price: 650,
      img: 
        "/images/43.webp",
      ingredients: [
        "Dut Meyvesi"
      ],
      warnings: [
        "Serin ve kuru bir yerde muhafaza ediniz.",
        "Kullanım önerilerine uyunuz."
      ],
      usage: {
        general: "Doğrudan ağız yoluyla veya içeceklerde ve yemeklerde kullanılabilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Lif içeriği ile sindirim sistemini destekler.",
        "Enerji sağlar ve kan şekerinin dengelenmesine yardımcı olur.",
        "Mineral ve vitamin ihtiyacını karşılar.",
        "Antioksidan koruma sağlar."
      ]
    },
    {
      _id: 23,
      productName: "Ödem Atmaya Yardımcı Doğal Ekşi Dağ Elması Detox Sirkesi 500 ML",
      ficheTech: [
        {
          label: "Yüksek rakımlı dağlarda yetişmiş yabandağ elmalarından üretilmiştir."
        },
        {
          label: "Doğal fermantasyon yöntemi ile bir yıl içinde üretilmiştir."
        },
        {
          label: "Tamamen doğal ve organiktir, ilaç, katkı maddesi veya pestisit içermez."
        },
        {
          label: "Yaban dağ elması, C vitamini ve çeşitli B vitaminleri (B1, B2, B5, B9) ile A ve E vitaminleri içerir."
        },
        {
          label: "Potasyum, magnezyum, fosfor, kalsiyum, çinko, demir ve oligo elementler içerir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This natural and organic mountain apple vinegar is produced from wild mountain apples grown at high altitudes. It is made using a natural fermentation process over the course of one year. The vinegar contains no chemicals, additives, or pesticides. Mountain apples, known as a type of forest apple, are rich in vitamins such as C, B1, B2, B5, B9, as well as A and E vitamins. They also contain essential minerals like potassium, magnesium, phosphorus, calcium, zinc, iron, and trace elements. Directions: Mix 1/3 of a small tea glass of apple vinegar with water and consume on an empty stomach in the morning and before bedtime at night.",
        tr: "Bu doğal ve organik dağ elması sirkesi, yüksek rakımlı dağlarda yetişmiş yabandağ elmalarından üretilmiştir. Bir yıllık doğal fermantasyon süreci ile üretilmiştir ve üretim aşamasında herhangi bir ilaç, katkı maddesi veya pestisit kullanılmamıştır. Yaban dağ elması, C vitamini başta olmak üzere B1, B2, B5, B9 vitaminlerini ve A ile E vitaminlerini barındırır. Ayrıca potasyum, magnezyum, fosfor, kalsiyum, çinko, demir ve oligo elementler içerir. Kullanım Talimatı: Sabah aç karnına bir çay bardağının 1/3'ü kadar elma sirkesini su ile karıştırarak için. Gece yatmadan önce aynı şekilde bir çay bardağının 1/3'ü kadar elma sirkesini su ile karıştırarak için."
      },
      cat: "Vinegars",
      pdf: "",
      extraInfo: "GMO-free",
      price: 275,
      img: 
        "/images/4.webp",
      ingredients: [
        "Yabandağ elması",
        "Doğal fermantasyon ile elde edilen elma sirkesi"
      ],
      warnings: [
        "Herhangi bir sağlık sorununuz varsa kullanmadan önce doktorunuza danışın.",
        "Alerjiniz varsa kullanmayınız."
      ],
      usage: {
        morning: "Sabah aç karnına bir çay bardağının 1/3 oranında elma sirkesi üzerine su ilave edilerek içilir.",
        night: "Gece yatmadan önce bir çay bardağının 1/3 oranında elma sirkesi üzerine su ilave edilerek içilir."
      },
      storage: "Serin ve kuru bir yerde, direkt güneş ışığından uzak tutarak saklayınız.",
      purpose: [
        "Ödem atmaya yardımcı olur.",
        "Detox etkisi sağlar.",
        "Bağışıklık sistemini güçlendirir."
      ]
    },
    {
      _id: 22,
      productName: "Fresh (Propolis, Okaliptüs Yağı ve Kozalak Pekmezi İçeren Bitkisel Karışım)",
      ficheTech: [
        {
          label: "Tamamen bitkisel içeriğe sahip."
        },
        {
          label: "İçeriğindeki 20 farklı bitkinin sinerjisi ile daha etkili."
        },
        {
          label: "Her bir bitkisel karışımın verimliliği 10 kat daha fazla."
        },
        {
          label: "Sağlığınıza en doğal ve hızlı şekilde katkı sağlar."
        },
        {
          label: "Tatlandırıcı içermemesine rağmen keyifle tüketilebilir."
        },
        {
          label: "Güvenilir ve kaliteli, kısa sürede etkisini gösterir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti Fresh is a herbal blend that supports respiratory health and alleviates cold symptoms. It is effective in relieving sore throats, sinusitis, and bronchitis, while also serving as an expectorant. This unique formula is beneficial for ulcers and helps lower blood sugar levels. Eucalyptus oil offers strong antibacterial effects against pathogenic bacteria in the upper respiratory tract. With antioxidant properties, Scuti Fresh aids in cleaning harmful free radicals and positively impacts chronic lung diseases like asthma and bronchitis. It can be used together with Immun Complex for enhanced effectiveness.",
        tr: "Scuti Fresh, solunum yollarını destekleyen ve soğuk algınlığı semptomlarını hafifleten bitkisel bir karışımdır. Boğaz ağrısı, sinüzit ve bronşiti rahatlatırken, öksürüğü keser ve balgam söktürücü olarak görev yapar. Bu eşsiz formül, ülser rahatsızlıklarına fayda sağlamasının yanı sıra kan şekerini düşürmeye yardımcı olur. Okaliptüs yağı, üst solunum yollarındaki patojenik bakterilere karşı güçlü bir antibakteriyel etki sunar. Antioksidan etkisiyle zararlı serbest radikalleri temizleyen Scuti Fresh, astım, bronşit gibi kronik akciğer hastalıklarına olumlu etki eder. İmmun Complex ile birlikte kullanımı önerilir."
      },
      cat: "Bitkisel Ürünler",
      pdf: "",
      extraInfo: "GMO-free",
      price: 275,
      img: 
        "/images/33.webp",
      ingredients: [
        "Propolis",
        "Okaliptüs Yağı (Eucalyptus Globulus)",
        "Kekik Yağı (Thymus Vulgaris, Origanum Vulgare)",
        "Nane Yağı (Mentha Viridis, Mentha Piperita)",
        "Fesleğen Yağı (Ocimum Basillicum)",
        "Menthol",
        "Servi Kozalağı Yağı (Cupressus Sempervirens)",
        "Zerdeçal Ekstraktı (Curcuma Longa)",
        "Kırmızı Üzüm Çekirdeği Yağı (Vitis Rotundifolia)",
        "Karamürver Ekstraktı (Sambucus Nigra)",
        "Söğüt Kabuğu Ekstraktı (Salix Alba)",
        "Sarımsak Yağı (Allium Sativum)",
        "Sahil Çamı Ekstraktı (Pinus Maritima)",
        "Biberiye Yağı (Rosmarinus Officinalis)",
        "Karanfil Yağı (Eugenia Caryophyllata)",
        "Çinko Sülfat Monohidrat",
        "Vitamin C (L-Ascorbic Acid)",
        "Vitamin D3 (Kolekalsiferol)",
        "Nane Pekmezi",
        "Servi Kozalağı Pekmezi"
      ],
      warnings: [
        "İçeriğindeki herhangi birine alerjiniz var ise kullanmayınız.",
        "İstenmeyen etki gördüğünüzde doktorunuza başvurunuz.",
        "2 yaşından küçük çocuk ve bebeklere önerilmez."
      ],
      usage: {
        children_4_10: "4-10 yaş arası çocuklar için günde iki defa yemeklerden 30 dk sonra 2,5 ml (yarım tatlı kaşığı) tüketilmesi önerilir.",
        children_11_adult: "11 yaş üzeri çocuklar ve yetişkinler için günde 3 defa yemeklerden 30 dk sonra 5 ml (bir tatlı kaşığı) tüketilmesi önerilir."
      },
      storage: "25°C'nin altında oda sıcaklığında, nemden ve güneş ışığından koruyarak muhafaza ediniz.",
      purpose: [
        "Astım, bronşit, nefes darlığı, KOAH, öksürük gibi kronik akciğer hastalıklarına olumlu etki eder.",
        "Fenolik bileşenleri ile antioksidan etkisi oluşturarak, vücutta biriken zararlı serbest radikalleri temizlemeye yardımcı olur.",
        "Soğuk algınlığı semptomlarını hafifletir.",
        "Boğaz ağrısı, sinüzit ve bronşiti rahatlatır.",
        "Öksürüğü keser ve balgam söktürücü görevi görür.",
        "Ülsere faydalıdır, kan şekerini düşürmeye yardımcı olur.",
        "Okaliptus yağının üst solunum yollarındaki patojenik bakteriler üzerinde antibakteriyel etkisi vardır.",
        "Boğazda tutunan mikropları temizleyerek, üst solunum yolu enfeksiyonlarının önüne geçer.",
        "Kozalak pekmezinin içeriğinde bulunan pinenin etkisiyle balgam söktürücü özellik gösterir.",
        "Yüksek C vitamini, çinko, magnezyum, fosfor ve doymamış yağ asitleri içerir; beyinde toksisiteye ve iltihaplanmaya sebep olacak zararlı maddeleri uzaklaştırır."
      ],
      note: "Fayda sağlayabilmesi için düzenli kullanım çok önemlidir. İmmun Complex ile kullanımı önerilir."
    },
    {
      _id: 36,
      productName: "Scuti Hurma Saf Organik Katkısız Hurma Pekmezi 350 gr",
      ficheTech: [
        {
          label: "Yüksek lif içeriği sindirim sistemini destekler ve vücudunuza gerekli besinleri sağlar."
        },
        {
          label: "Doğal şeker kaynağı ile enerji sağlar ve kan şekerinin dengelenmesine yardımcı olabilir."
        },
        {
          label: "Mineraller ve vitaminler bakımından zengindir, vücudunuzun günlük ihtiyaçlarını karşılamaya destek olur."
        },
        {
          label: "Antioksidanlar bakımından zengindir, serbest radikallerle savaşır ve hücreleri korur."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti Hurma Saf Organik Katkısız Hurma Pekmezi offers a natural and healthy alternative with its unique taste. Made from carefully selected organic dates, this special molasses contains pure extract. It provides a healthful experience with its high fiber, vitamin, and mineral content. The high fiber supports the digestive system and provides essential nutrients to your body. This molasses also acts as a natural energy source due to its natural sugar content, which can help balance blood sugar levels. Rich in minerals and vitamins, it supports your daily nutritional needs. Additionally, it is abundant in antioxidants that fight free radicals, protecting cells and contributing to overall health. Ideal for a healthy lifestyle, this molasses adds natural goodness and flavor to your diet. Follow the product label and usage recommendations for best results.",
        tr: "Scuti Hurma Saf Organik Katkısız Hurma Pekmezi, sağlıklı ve lezzetli bir alternatif sunarak sofralarınıza doğallık katar. Özenle seçilmiş organik hurma meyvelerinden elde edilen bu özel pekmez, saf bir öz içerir. Yüksek lif, vitamin ve mineral bakımından zengin içeriği ile sağlığınıza katkıda bulunur. Yüksek lif içeriği sindirim sistemini destekler ve vücudunuza gerekli besinleri sağlar. Doğal şeker kaynağı olan hurma özü sayesinde enerji dolu bir atış sağlar ve kan şekerinin dengelenmesine yardımcı olabilir. İçeriğindeki mineraller ve vitaminler, vücudunuzun günlük ihtiyaçlarını karşılamaya destek olur. Ayrıca, antioksidanlar bakımından zengindir ve serbest radikallerle savaşarak hücreleri korur, genel sağlığa katkıda bulunabilir. Sağlıklı yaşam tarzının vazgeçilmez bir parçası olabilir, doğallığı ve besleyici özellikleri ile sofralarınıza sağlık ve lezzet getirir. Ürün etiketi ve içerik bilgileri doğrultusunda kullanım önerilerini takip etmek önemlidir."
      },
      cat: "Molasses",
      pdf: "",
      extraInfo: "Organik ve katkısız içerikler kullanılarak üretilmiştir.",
      price: 650,
      img: 
        "/images/44.webp",
      ingredients: [
        "Hurma Meyvesi"
      ],
      warnings: [
        "Serin ve kuru bir yerde muhafaza ediniz.",
        "Kullanım önerilerine uyunuz."
      ],
      usage: {
        general: "Doğrudan ağız yoluyla veya içeceklerde ve yemeklerde kullanılabilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Lif içeriği ile sindirim sistemini destekler.",
        "Enerji sağlar ve kan şekerinin dengelenmesine yardımcı olur.",
        "Mineral ve vitamin ihtiyacını karşılar.",
        "Antioksidan koruma sağlar."
      ]
    },
    {
      _id: 30,
      productName: "Women's Tea Kadınlar İçin Özel Üretilmiş Bitki Çayı 20'li Servis",
      ficheTech: [
        {
          label: "Kullanım Şekli: Günde iki defa, sabah ve akşam olmak üzere bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenir ve tüketilir."
        },
        {
          label: "Uyarılar: Serin ve rutubetsiz ortamda muhafaza ediniz. İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız. Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
        },
        {
          label: "Not: Tüm ürünlerimiz herkese özel olarak üretilir. Bu ürünümüz sadece kadınlara özeldir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This herbal tea is specially formulated for women, providing unique health benefits. Usage: Steep one tea bag in a cup of hot water for 3-4 minutes, twice a day—morning and evening. Warnings: Store in a cool, dry place. Avoid use if you have allergies to any of the ingredients. If an unexpected reaction occurs, consult your doctor.",
        tr: "Bu bitkisel çay, kadınlar için özel olarak formüle edilmiştir ve benzersiz sağlık faydaları sunar. Kullanım Şekli: Günde iki defa, sabah ve akşam olmak üzere bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenir ve tüketilir. Uyarılar: Serin ve rutubetsiz ortamda muhafaza ediniz. İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız. Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
      },
      cat: "Herbal Teas",
      pdf: "",
      extraInfo: "Kadınlar için özel",
      price: 400,
      img: 
        "/images/26.webp",
      ingredients: [],
      warnings: [
        "Serin ve rutubetsiz ortamda muhafaza ediniz.",
        "İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız.",
        "Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
      ],
      usage: {
        daily: "Günde iki defa, sabah ve akşam bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenir ve tüketilir."
      },
      storage: "Serin ve kuru bir yerde, rutubetsiz ortamda muhafaza ediniz.",
      purpose: [
        "Kadın sağlığına özel faydalar sunar."
      ]
    },
    {
      _id: 25,
      productName: "Çürük Önlemeye Yardımcı Kokulu Kara Üzüm Sirkesi 500 ML",
      ficheTech: [
        {
          label: "Üzüm, yaprak döken odunsu asmaların vitis cinsinden çiçekli bitki meyvesidir."
        },
        {
          label: "Üzüm, dünyadaki kültürü yapılan en eski meyve türlerinden biridir."
        },
        {
          label: "Fosfor, magnezyum, kalsiyum ve A ile C vitaminleri açısından zengindir."
        },
        {
          label: "Üzüm, şarap, reçel, üzüm suyu, jöle, üzüm çekirdeği özü, kuru üzüm, sirke ve üzüm çekirdeği yağı yapımında kullanılabilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This aromatic black grape vinegar is produced from one of the world's oldest cultivated fruits, grapes, which belong to the vitis genus of flowering plants. Grapes are known for their diverse varieties and numerous health benefits, being rich in phosphorus, magnesium, calcium, and vitamins A and C. This versatile fruit can be consumed fresh or used in the production of wine, jam, grape juice, jelly, grape seed extract, raisins, vinegar, and grape seed oil. Directions: Mix 1/3 of a small tea glass of black grape vinegar with water and consume daily to help prevent tooth decay.",
        tr: "Bu kokulu kara üzüm sirkesi, yaprak döken odunsu asmaların vitis cinsinden çiçekli bitki meyvesi olan üzümlerden üretilmiştir. Üzüm, dünyadaki en eski kültür bitkilerinden biri olup, fosfor, magnezyum, kalsiyum ve A ile C vitaminleri açısından zengin bir meyvedir. Üzüm, sofralık olarak taze tüketilebilir veya şarap, reçel, üzüm suyu, jöle, üzüm çekirdeği özü, kuru üzüm, sirke ve üzüm çekirdeği yağı yapımında kullanılabilir. Kullanım Talimatı: Diş çürüklerini önlemeye yardımcı olmak için günlük olarak bir çay bardağının 1/3'ü kadar kara üzüm sirkesini su ile karıştırarak tüketin."
      },
      cat: "Vinegars",
      pdf: "",
      extraInfo: "GMO-free",
      price: 325,
      img: 
        "/images/2.webp",
      ingredients: [
        "Kara üzüm",
        "Doğal fermantasyon ile elde edilen üzüm sirkesi"
      ],
      warnings: [
        "Herhangi bir sağlık sorununuz varsa kullanmadan önce doktorunuza danışın.",
        "Alerjiniz varsa kullanmayınız."
      ],
      usage: {
        daily: "Diş çürüklerini önlemeye yardımcı olmak için günlük olarak bir çay bardağının 1/3'ü kadar kara üzüm sirkesini su ile karıştırarak tüketin."
      },
      storage: "Serin ve kuru bir yerde, direkt güneş ışığından uzak tutarak saklayınız.",
      purpose: [
        "Diş çürüklerini önlemeye yardımcı olur.",
        "Antioksidan özelliği ile vücudu serbest radikallere karşı korur.",
        "Bağışıklık sistemini güçlendirir."
      ]
    },
    {
      _id: 43,
      productName: "Karanfil Yağı %100 Doğal Bitkisel Uçucu Yağ Clove Oil 10ml",
      ficheTech: [
        {
          label: "Yüksek karanfil özleri içeren %100 doğal uçucu yağ."
        },
        {
          label: "Antiseptik, antibakteriyel ve analjezik özellikler sağlar."
        },
        {
          label: "Cilt ve saç bakımında kullanıma uygundur."
        },
        {
          label: "Doğal ve saf içeriği ile hazırlanmıştır."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Clove Oil is a high-quality essential oil renowned for its potent antiseptic, antibacterial, and analgesic properties. Extracted from clove buds, this oil offers natural support for skin and hair care. Its rich composition makes it ideal for soothing and healing skin irritations, enhancing scalp health, and providing a warming sensation to relieve minor aches. Perfect for those seeking a versatile, natural addition to their wellness routine, Scuti Clove Oil delivers the concentrated benefits of cloves in a pure and effective form.",
        tr: "Scuti %100 Saf Karanfil Yağı, güçlü antiseptik, antibakteriyel ve analjezik özellikleri ile tanınan yüksek kaliteli bir uçucu yağdır. Karanfil tomurcuklarından elde edilen bu yağ, cilt ve saç bakımında doğal destek sunar. Zengin bileşenleri sayesinde cilt tahrişlerini yatıştırmaya, saç sağlığını artırmaya ve hafif ağrıları rahatlatmaya yardımcı olabilir. Doğal bir bakım rutini arayanlar için ideal olan Scuti Karanfil Yağı, karanfillerin yoğun faydalarını saf ve etkili bir formda sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Karanfil tomurcuklarından elde edilen %100 doğal uçucu yağ.",
      price: 400,
      img: 
        "/images/86.webp",
      ingredients: [
        "Karanfil Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Cilt ve saç bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek cilt üzerine masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Cilt tahrişlerini yatıştırır ve iyileştirir.",
        "Saç sağlığını destekler.",
        "Antiseptik ve antibakteriyel etkiler sağlar."
      ]
    },
    {
      _id: 33,
      productName: "Scuti Harnup Saf Organik Katkısız Keçiboynuzu Özü 350 gr",
      ficheTech: [
        {
          label: "Yüksek lif içeriği ile zengindir."
        },
        {
          label: "Doğal şeker kaynağı olarak enerji sağlar ve kan şekerinin dengelenmesine yardımcı olabilir."
        },
        {
          label: "Kalsiyum, demir, magnezyum, potasyum gibi mineraller ve A, B, C vitaminlerini içerir."
        },
        {
          label: "Antioksidanlar içerir, serbest radikallerle savaşarak hücrelerin korunmasına yardımcı olur ve anti-aging özelliklere sahiptir."
        },
        {
          label: "Kalp sağlığını destekleyebilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti Harnup Saf Organik Katkısız Keçiboynuzu Özü is a supplement for natural and healthy nutrition. Keçiboynuzu, a sweet, dark brown fruit commonly found in the Mediterranean region, is popular for its nutritional value and health benefits. The product contains pure and natural carob extract and has the following features: High in fiber, a natural source of energy, contains minerals and vitamins such as calcium, iron, magnesium, potassium, and vitamins A, B, and C. It also includes antioxidants that help protect cells and support heart health. It can be consumed directly, in drinks, or in foods. Consult a healthcare professional before use, especially during pregnancy, breastfeeding, or if you have health issues.",
        tr: "Scuti Harnup Saf Organik Katkısız Keçiboynuzu Özü, doğal ve sağlıklı beslenmeye yönelik bir takviye ürünüdür. Keçiboynuzu, genellikle Akdeniz bölgesinde yetişen, koyu kahverengi renkte tatlı bir meyvedir. Bu ürün, saf ve doğal keçiboynuzu özünü içerir ve yüksek lif içeriği, doğal şeker kaynağı olarak enerji sağlama, kalsiyum, demir, magnezyum, potasyum gibi mineraller ve A, B, C vitaminlerini içerme gibi özelliklere sahiptir. Ayrıca, antioksidanlar içerir, hücrelerin korunmasına yardımcı olur ve kalp sağlığını destekleyebilir. Doğrudan ağız yoluyla, içeceklerde veya yemeklerde kullanılabilir. Ancak, herhangi bir takviye ürünü kullanmadan önce, özellikle hamilelik, emzirme veya sağlık sorunlarınız varsa, bir sağlık profesyoneli ile görüşmeniz önerilir."
      },
      cat: "Molasses",
      pdf: "",
      extraInfo: "Doğal ve organik içeriklerden üretilmiştir.",
      price: 750,
      img: 
        "/images/41.webp",
      ingredients: [
        "Keçiboynuzu Özü"
      ],
      warnings: [
        "Serin ve kuru bir yerde muhafaza ediniz.",
        "Hamilelik, emzirme veya sağlık sorunları durumunda kullanmadan önce bir sağlık profesyoneli ile görüşünüz."
      ],
      usage: {
        general: "Doğrudan ağız yoluyla, içeceklerde veya yemeklerde kullanılabilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Lif içeriği sağlar.",
        "Doğal enerji kaynağı olarak kullanılır.",
        "Mineral ve vitamin ihtiyacını karşılar.",
        "Antioksidan koruma sunar.",
        "Kalp sağlığını destekler."
      ]
    },
    {
      _id: 37,
      productName: "Kekik Uçucu Yağı - %100 Saf Karvakrol Kekik Yağı - 10 Ml (Yüksek Carvacrol)",
      ficheTech: [
        {
          label: "Yüksek carvacrol içeriği ile etkili bir uçucu yağdır."
        },
        {
          label: "Doğal ve saf kekik yağı olarak hazırlanmıştır."
        },
        {
          label: "Cilt ve saç bakımında kullanılabilir."
        },
        {
          label: "Antiseptik, antibakteriyel ve antifungal özelliklere sahiptir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Carvacrol Thyme Oil is a premium essential oil renowned for its high carvacrol content. This potent essential oil is crafted to offer natural and effective care for your skin and hair. With its powerful antiseptic, antibacterial, and antifungal properties, it can be used to help cleanse and purify the skin, promote a healthy scalp, and support overall wellness. Ideal for those seeking a natural solution for their personal care routine, Scuti Thyme Oil delivers the concentrated benefits of thyme in a pure and potent form.",
        tr: "Scuti %100 Saf Karvakrol Kekik Yağı, yüksek karvakrol içeriği ile tanınan bir uçucu yağdır. Bu etkili yağ, cilt ve saç bakımında doğal ve etkili çözümler sunmak üzere hazırlanmıştır. Güçlü antiseptik, antibakteriyel ve antifungal özellikleri sayesinde cildinizi temizlemeye, saçı sağlıklı tutmaya ve genel sağlığı desteklemeye yardımcı olabilir. Doğal bakım rutini arayanlar için ideal bir tercihtir ve kekik bitkisinin yoğun faydalarını saf ve etkili bir formda sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Yüksek carvacrol içeriği ile hazırlanmış saf kekik yağı.",
      price: 450,
      img: 
        "/images/80.webp",
      ingredients: [
        "Karvakrol Kekik Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Cilt ve saç bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Cilt temizliği ve bakımında kullanılır.",
        "Saç sağlığını destekler.",
        "Antiseptik ve antibakteriyel etkiler sağlar."
      ]
    },
    {
      _id: 27,
      productName: "Cildin Güzelleşmesine Yardımcı Kiraz Sirkesi 500 ML",
      ficheTech: [
        {
          label: "Kuzey Akdeniz kıyıları, Güney Kafkasya, Hazar Denizi ve Kuzeydoğu Anadolu'da doğal olarak yetişmiş kirazlardan üretilmiştir."
        },
        {
          label: "Doğal fermantasyon yöntemiyle üretilmiştir."
        },
        {
          label: "Kiraz sirkesi, meyve, sap ve çekirdekleri içerdiğinden dolayı oldukça faydalıdır."
        },
        {
          label: "Cilt sağlığını olumlu yönde etkiler ve selülitlerin azalmasına yardımcı olur."
        },
        {
          label: "Yüksek oranda A ve C vitamini içermektedir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This cherry vinegar is made from cherries naturally grown in the coastal regions of the Northern Mediterranean, South Caucasus, Caspian Sea, and Northeastern Anatolia. Through a natural fermentation process, the vinegar is rich in A and C vitamins, and it includes the fruit, stems, and seeds of the cherries. Cherry vinegar positively impacts skin health, reduces cellulite, and is beneficial for heart health. The full essence of cherries is extracted during fermentation, making this vinegar one of the healthiest options.",
        tr: "Bu kiraz sirkesi, Kuzey Akdeniz kıyıları, Güney Kafkasya, Hazar Denizi ve Kuzeydoğu Anadolu'da doğal olarak yetişmiş kirazlardan üretilmiştir. Doğal fermantasyon süreci ile üretilen sirke, yüksek oranda A ve C vitamini içerir ve meyve, sap, çekirdek gibi tüm kiraz parçalarını barındırır. Kiraz sirkesi, cilt sağlığını olumlu yönde etkiler, selülitlerin azalmasına yardımcı olur ve kalp sağlığına fayda sağlar. Fermantasyon işlemi sırasında kirazın tüm özleri ortaya çıkar, bu da kiraz sirkesini sağlık açısından en faydalı Vinegarsden biri yapar."
      },
      cat: "Vinegars",
      pdf: "",
      extraInfo: "GMO-free",
      price: 375,
      img: 
        "/images/3.webp",
      ingredients: [
        "Kiraz",
        "Doğal fermantasyon ile elde edilen kiraz sirkesi"
      ],
      warnings: [
        "Hamileyseniz veya emziriyorsanız kullanmadan önce doktorunuza danışın.",
        "Alerjiniz varsa kullanmayınız."
      ],
      usage: {
        daily: "Cilt sağlığını desteklemek için günlük olarak bir çay bardağının 1/3'ü kadar kiraz sirkesini su ile karıştırarak tüketin."
      },
      storage: "Serin ve kuru bir yerde, direkt güneş ışığından uzak tutarak saklayınız.",
      purpose: [
        "Cilt sağlığını destekler.",
        "Selülitlerin azalmasına yardımcı olur.",
        "Kalp sağlığını destekler."
      ]
    },
    {
      _id: 44,
      productName: "Lavanta Uçucu Yağı 10 ml %100 Saf Ve Doğal (Lavender Essential Oil)",
      ficheTech: [
        {
          label: "%100 saf ve doğal lavanta uçucu yağı."
        },
        {
          label: "Rahatlatıcı ve sakinleştirici özelliklere sahiptir."
        },
        {
          label: "Cilt ve saç bakımında kullanılabilir."
        },
        {
          label: "Doğal ve etkili bir rahatlama ve iyileşme desteği sunar."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Lavender Essential Oil is a premium quality oil known for its soothing and calming properties. Extracted from the lavender plant, this essential oil is perfect for promoting relaxation, reducing stress, and improving sleep quality. It can be used in various ways, including skin care routines and hair treatments, to benefit from its natural healing and rejuvenating effects. Ideal for those seeking a natural solution to enhance their well-being, Scuti Lavender Oil offers the pure essence of lavender in an effective form.",
        tr: "Scuti %100 Saf Lavanta Uçucu Yağı, yatıştırıcı ve sakinleştirici özellikleri ile tanınan yüksek kaliteli bir yağdır. Lavanta bitkisinden elde edilen bu uçucu yağ, rahatlama, stres azaltma ve uyku kalitesini artırma konusunda mükemmel bir seçenektir. Cilt bakım rutinlerinde ve saç bakımında kullanılabilir, doğal iyileştirici ve gençleştirici etkilerinden faydalanabilirsiniz. Sağlığını artırmak isteyenler için ideal bir doğal çözüm sunan Scuti Lavanta Yağı, lavantanın saf özünü etkili bir formda sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Lavanta bitkisinden elde edilen %100 saf ve doğal uçucu yağ.",
      price: 350,
      img: 
        "/images/87.webp",
      ingredients: [
        "Lavanta Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Cilt ve saç bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir. Ayrıca, rahatlama ve uyku desteği için yatmadan önce birkaç damla yastığınıza eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Rahatlama ve stres azaltma sağlar.",
        "Uyku kalitesini artırır.",
        "Cilt ve saç bakımında kullanılır."
      ]
    },
    {
      _id: 40,
      productName: "Nane Yağı %100 Doğal Bitkisel Uçucu Yağ Peppermint Oil 10ml",
      ficheTech: [
        {
          label: "%100 doğal ve saf bitkisel uçucu yağdır."
        },
        {
          label: "Taze nane özleri içerir."
        },
        {
          label: "Cilt ve saç bakımında kullanılabilir."
        },
        {
          label: "Ferahlık ve rahatlatıcı özelliklere sahiptir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Natural Peppermint Oil is a premium essential oil known for its refreshing and soothing properties. This high-quality peppermint oil is designed to provide natural care for both skin and hair. Its invigorating aroma helps to clear the mind, relieve stress, and promote relaxation. Peppermint oil is also valued for its cooling effect on the skin and its ability to soothe sore muscles. Ideal for those seeking a natural solution for their personal care routine, Scuti Peppermint Oil offers the concentrated benefits of peppermint in a pure and potent form.",
        tr: "Scuti %100 Doğal Nane Yağı, ferahlatıcı ve rahatlatıcı özellikleriyle tanınan yüksek kaliteli bir uçucu yağıdır. Bu yüksek kaliteli nane yağı, hem cilt hem de saç bakımı için doğal çözümler sunmak üzere tasarlanmıştır. Canlandırıcı aroması, zihni temizlemeye, stresi hafifletmeye ve rahatlamayı teşvik etmeye yardımcı olur. Nane yağı ayrıca ciltte soğutma etkisi ve ağrıyan kasları yatıştırma özelliği ile değerli bir üründür. Doğal bakım rutini arayanlar için ideal bir tercihtir ve nane bitkisinin yoğun faydalarını saf ve etkili bir formda sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Yüksek kaliteli nane özleri ile hazırlanmış %100 doğal uçucu yağ.",
      price: 400,
      img: 
        "/images/83.webp",
      ingredients: [
        "Nane Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Cilt ve saç bakımında kullanılabilir. Bir taşıyıcı yağ ile seyreltilerek masaj yapılabilir veya birkaç damla buharlaştırıcıya eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Ciltte ferahlatıcı ve rahatlatıcı etkiler sağlar.",
        "Ağrıyan kasları yatıştırır ve soğutma etkisi sunar.",
        "Zihinsel netliği artırır ve stresi azaltır."
      ]
    },
    {
      _id: 38,
      productName: "Nioli Uçucu Yağı 10 ml %100 Saf Ve Doğal Genital Enfeksiyon & Koku Önleme",
      ficheTech: [
        {
          label: "100% saf ve doğal nioli yağı."
        },
        {
          label: "Genital bölgedeki enfeksiyon ve kötü kokuları önlemeye yardımcı olur."
        },
        {
          label: "Antiseptik ve antibakteriyel özelliklere sahiptir."
        },
        {
          label: "Doğal ve etkili bir kişisel bakım çözümüdür."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Nioli Essential Oil offers a natural and effective solution for genital health and hygiene. This premium oil is renowned for its antiseptic and antibacterial properties, which help prevent infections and unwanted odors in the genital area. Crafted from pure nioli oil, it provides a gentle yet powerful way to maintain cleanliness and freshness. Ideal for personal care routines, Scuti Nioli Oil supports overall genital health while ensuring a natural approach to hygiene.",
        tr: "Scuti %100 Saf Nioli Uçucu Yağı, genital sağlık ve hijyen için doğal ve etkili bir çözüm sunar. Bu premium yağ, genital bölgede enfeksiyon ve istenmeyen kokuları önlemeye yardımcı olan antiseptik ve antibakteriyel özellikleri ile tanınır. Saf nioli yağından üretilen bu ürün, temizlik ve ferahlığı korumak için nazik ama güçlü bir yol sunar. Kişisel bakım rutininiz için ideal olan Scuti Nioli Yağı, genital sağlığı desteklerken hijyen konusunda doğal bir yaklaşım sağlar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Genital bölgede enfeksiyon ve kötü kokuları önlemeye yardımcı doğal nioli yağı.",
      price: 375,
      img: 
        "/images/81.webp",
      ingredients: [
        "Saf Nioli Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın.",
        "Yalnızca harici kullanım içindir."
      ],
      usage: {
        general: "Bir taşıyıcı yağ ile seyreltilerek genital bölgeye hafifçe masaj yapılabilir veya banyo suyuna birkaç damla eklenebilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Genital bölgede enfeksiyonları önlemeye yardımcı olur.",
        "Kötü kokuları azaltır ve temizliği destekler.",
        "Antiseptik ve antibakteriyel etkiler sağlar."
      ]
    },
    {
      _id: 45,
      productName: "Okaliptus Uçucu Yağı 10 ml %100 Saf Ve Doğal (Eucalyptus Essential Oil)",
      ficheTech: [
        {
          label: "%100 saf ve doğal okaliptus uçucu yağı."
        },
        {
          label: "Ferahlatıcı ve temizleyici özelliklere sahiptir."
        },
        {
          label: "Solunum yollarını rahatlatır ve enerji verir."
        },
        {
          label: "Cilt ve saç bakımında kullanılabilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti 100% Pure Eucalyptus Essential Oil is a high-quality oil known for its invigorating and refreshing properties. Extracted from the eucalyptus plant, this essential oil is ideal for promoting respiratory health, relieving congestion, and enhancing mental clarity. It can be used in various ways, including as a natural remedy for respiratory issues, in skin and hair care routines, and to create a refreshing and energizing atmosphere. Scuti Eucalyptus Oil offers the pure essence of eucalyptus in a potent and effective form.",
        tr: "Scuti %100 Saf Okaliptus Uçucu Yağı, canlandırıcı ve ferahlatıcı özellikleri ile tanınan yüksek kaliteli bir yağdır. Okaliptus bitkisinden elde edilen bu uçucu yağ, solunum yollarını rahatlatmak, tıkanıklığı gidermek ve zihinsel netliği artırmak için idealdir. Cilt ve saç bakımında kullanılabilir, ayrıca ferahlatıcı ve enerji verici bir atmosfer yaratmak için kullanılabilir. Scuti Okaliptus Yağı, okaliptusun saf özünü etkili ve güçlü bir formda sunar."
      },
      cat: "Care Oils",
      pdf: "",
      extraInfo: "Okaliptus bitkisinden elde edilen %100 saf ve doğal uçucu yağ.",
      price: 400,
      img: 
        "/images/88.webp",
      ingredients: [
        "Okaliptus Yağı"
      ],
      warnings: [
        "Kullanım öncesi alerji testi yapınız.",
        "Gözle temastan kaçının.",
        "Hamilelik ve emzirme döneminde kullanmadan önce doktorunuza danışın."
      ],
      usage: {
        general: "Solunum yollarını rahatlatmak için birkaç damla buharlaştırıcıya eklenebilir. Cilt ve saç bakımında kullanılabilir. Ayrıca, enerji ve ferahlık sağlamak için oda spreyi olarak kullanılabilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Solunum yollarını rahatlatır ve tıkanıklığı giderir.",
        "Enerji ve ferahlık sağlar.",
        "Cilt ve saç bakımında kullanılır."
      ]
    },
    {
      _id: 28,
      productName: "Saç Besleyici ve Onarıcı Saç Bakım Toniği 100 ML",
      ficheTech: [
        {
          label: "Hiçbir sentetik kimyasal içermez."
        },
        {
          label: "Sağlıklı bir şekilde saçların uzamasını sağlar."
        },
        {
          label: "Düzenli kullanımda saç dökülmesini ve kırılmayı önler."
        },
        {
          label: "Saç spreyi, jöle, röfle gibi uygulamaların yıpratıcı etkilerine karşı koruma sağlar."
        },
        {
          label: "Saç dibi egzaması ve deri hastalıklarında düzenli kullanımda gözle görülür sonuçlar sağlar."
        },
        {
          label: "Her tür saç tipine uygundur."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This hair nourishing and repairing tonic contains no synthetic chemicals and promotes healthy hair growth. With regular use, it helps prevent hair loss and breakage, protects against the damaging effects of hair sprays, gels, and highlights, and provides visible results in treating scalp eczema and skin conditions. Suitable for all hair types. Usage: Apply to the scalp three times a day with a gentle massage.",
        tr: "Bu saç besleyici ve onarıcı tonik, hiçbir sentetik kimyasal içermez ve saçların sağlıklı bir şekilde uzamasını destekler. Düzenli kullanımda saç dökülmesini ve kırılmayı önler, saç spreyi, jöle, röfle gibi uygulamaların yıpratıcı etkilerine karşı koruma sağlar. Saç dibi egzaması ve deri hastalıklarında düzenli kullanımda gözle görülür sonuçlar elde edilir. Her tür saç tipine uygundur. Kullanım Şekli: Günde 3 defa saç diplerine masaj yaparak uygulayınız."
      },
      cat: "Saç Bakım Ürünleri",
      pdf: "",
      extraInfo: "Kimyasal içermez",
      price: 600,
      img: 
        "/images/30.webp",
      ingredients: [
        "Doğal bitki özleri",
        "E vitamini",
        "B vitamini kompleksi"
      ],
      warnings: [
        "Çocukların ulaşamayacağı yerde saklayınız.",
        "Göz ile temasından kaçınınız."
      ],
      usage: {
        daily: "Günde 3 defa saç diplerine masaj yaparak uygulayınız."
      },
      storage: "Serin ve kuru bir yerde, direkt güneş ışığından uzak tutarak saklayınız.",
      purpose: [
        "Saçları besler ve onarır.",
        "Saç dökülmesini ve kırılmayı önler.",
        "Saç dibi egzaması ve deri hastalıklarında gözle görülür sonuçlar sağlar."
      ]
    },
    {
      _id: 24,
      productName: "Kolesterolü Dengelemeye Yardımcı Yaban Dağ Alıcı Sirkesi 500 ML",
      ficheTech: [
        {
          label: "Yüksek rakımlı dağlarda yetişmiş yaban dağ alıçlarından üretilmiştir."
        },
        {
          label: "Doğal fermantasyon yöntemi ile bir yıl içinde üretilmiştir."
        },
        {
          label: "Tamamen doğal ve organiktir, ilaç, katkı maddesi veya pestisit içermez."
        },
        {
          label: "Alıç, gülgiller ailesinden olup, C vitamini açısından zengindir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This natural and organic hawthorn vinegar is made from wild mountain hawthorns grown at high altitudes. It is produced using a natural fermentation process over the course of one year without any chemicals, additives, or pesticides. Hawthorn, a member of the rose family, is rich in vitamin C and is known for its health benefits. The vinegar is further enriched with natural flower honey and Trabzon persimmon, which help in balancing cholesterol levels, improving heart and vascular health, and regulating blood pressure. Directions: Mix 1/3 of a small tea glass of hawthorn vinegar with water and consume on an empty stomach in the morning and before bedtime at night.",
        tr: "Bu doğal ve organik alıç sirkesi, yüksek rakımlı dağlarda yetişmiş yaban dağ alıçlarından üretilmiştir. Bir yıllık doğal fermantasyon süreci ile üretilmiştir ve üretim aşamasında herhangi bir ilaç, katkı maddesi veya pestisit kullanılmamıştır. Alıç, gülgiller ailesinden olup, C vitamini açısından zengin bir meyvedir ve sağlık açısından birçok faydası vardır. Sirkemiz, doğal çiçek balı ve Trabzon hurması ile zenginleştirilmiş olup, kolesterol dengesinin sağlanmasına, kalp ve damar sağlığının iyileştirilmesine ve yüksek kan basıncının düzenlenmesine yardımcı olur. Kullanım Talimatı: Sabah aç karnına bir çay bardağının 1/3'ü kadar alıç sirkesini su ile karıştırarak için. Gece yatmadan önce aynı şekilde bir çay bardağının 1/3'ü kadar alıç sirkesini su ile karıştırarak için."
      },
      cat: "Vinegars",
      pdf: "",
      extraInfo: "GMO-free",
      price: 275,
      img: 
        "/images/5.webp",
      ingredients: [
        "Yaban dağ alıcı",
        "Doğal fermantasyon ile elde edilen alıç sirkesi"
      ],
      warnings: [
        "Herhangi bir sağlık sorununuz varsa kullanmadan önce doktorunuza danışın.",
        "Alerjiniz varsa kullanmayınız."
      ],
      usage: {
        morning: "Sabah aç karnına bir çay bardağının 1/3 oranında alıç sirkesi üzerine su ilave edilerek içilir.",
        night: "Gece yatmadan önce bir çay bardağının 1/3 oranında alıç sirkesi üzerine su ilave edilerek içilir."
      },
      storage: "Serin ve kuru bir yerde, direkt güneş ışığından uzak tutarak saklayınız.",
      purpose: [
        "Kolesterolü dengelemeye yardımcı olur.",
        "Kalp ve damar sağlığını iyileştirir.",
        "Yüksek tansiyonu düzenler.",
        "Bağışıklık sistemini güçlendirir."
      ]
    },
    {
      _id: 34,
      productName: "Scuti Andız Saf Organik Katkısız Ardıç Pekmezi 350 gr",
      ficheTech: [
        {
          label: "Yüksek lif içeriği ile sindirim sistemini destekler ve enerji sağlar."
        },
        {
          label: "Kalsiyum, demir, magnezyum, potasyum gibi mineralleri ve A, B, C vitaminlerini içerir."
        },
        {
          label: "Antioksidanlar bakımından zengindir, hücreleri korur ve genel sağlığa katkıda bulunabilir."
        },
        {
          label: "Doğal şekerleri ile enerji sağlar ve kan şekerinin dengelenmesine yardımcı olabilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Scuti Andız Saf Organik Katkısız Ardıç Pekmezi offers a unique taste and healthy properties from nature. Made from carefully selected organic juniper berries, this special molasses contains pure extract. It supports the digestive system with its high fiber content, provides energy, and can be used as a natural sweetener. Rich in minerals such as calcium, iron, magnesium, potassium, and vitamins A, B, and C, it meets daily nutritional needs. The molasses is also rich in antioxidants that help protect cells and contribute to overall health. Its natural sugars provide energy and can help balance blood sugar levels. Ideal for those seeking a natural and healthy lifestyle. Follow the product label and usage recommendations for best results.",
        tr: "Scuti Andız Saf Organik Katkısız Ardıç Pekmezi, doğadan gelen eşsiz lezzet ve sağlıklı özellikler sunar. Özenle seçilmiş organik ardıç meyvelerinden elde edilen bu özel pekmez, saf bir öz içerir. Yüksek lif içeriği sindirim sistemini destekler, enerji sağlar ve doğal bir tatlandırıcı olarak kullanılabilir. Kalsiyum, demir, magnezyum, potasyum gibi mineraller ve A, B, C vitaminleri içeren bu ürün, günlük besin ihtiyaçlarını karşılamaya yardımcı olur. Ayrıca, antioksidanlar bakımından zengindir ve hücreleri koruyarak genel sağlığa katkıda bulunabilir. Doğal şekerleri ile enerji sağlar ve kan şekerinin dengelenmesine yardımcı olabilir. Organik ve katkısız içeriği ile güvenle tüketilebilir. Sağlıklı bir yaşam tarzına katkı sağlamak isteyenler için ideal bir tercihtir. Ürün etiketi ve kullanım önerilerine göre tüketilmesi önemlidir."
      },
      cat: "Molasses",
      pdf: "",
      extraInfo: "Organik ve katkısız içerikler kullanılarak üretilmiştir.",
      price: 600,
      img: 
        "/images/42.webp",
      ingredients: [
        "Ardıç Meyvesi"
      ],
      warnings: [
        "Serin ve kuru bir yerde muhafaza ediniz.",
        "Kullanım önerilerine uyunuz."
      ],
      usage: {
        general: "Doğrudan ağız yoluyla veya içeceklerde ve yemeklerde kullanılabilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Lif içeriği ile sindirim sistemini destekler.",
        "Enerji sağlar ve kan şekerinin dengelenmesine yardımcı olur.",
        "Mineral ve vitamin ihtiyacını karşılar.",
        "Antioksidan koruma sağlar."
      ]
    },
    {
      _id: 29,
      productName: "Kilo Vermeye Yardımcı Bitkisel Detoks Form Çayı 45'li Poşet",
      ficheTech: [
        {
          label: "Kullanım Şekli: Günde üç defa, sabah, öğle ve akşam olmak üzere bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenerek tüketilir."
        },
        {
          label: "Uyarılar: Serin ve rutubetsiz ortamda muhafaza ediniz. İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız. Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
        },
        {
          label: "Not: Tüm ürünlerimiz özel olarak üretilir. Bu ürünümüz zayıflamak için kullanılır."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This herbal detox tea supports weight loss and is specially formulated to help you achieve your fitness goals. Usage: Steep one tea bag in a cup of hot water for 3-4 minutes, three times a day—morning, noon, and evening. Warnings: Store in a cool, dry place. Avoid use if you have allergies to any of the ingredients. If an unexpected reaction occurs, consult your doctor.",
        tr: "Bu bitkisel detoks çayı, kilo vermeyi destekler ve zayıflamak için özel olarak formüle edilmiştir. Kullanım Şekli: Günde üç defa, sabah, öğle ve akşam olmak üzere bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenerek tüketilir. Uyarılar: Serin ve rutubetsiz ortamda muhafaza ediniz. İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız. Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
      },
      cat: "Herbal Teas",
      pdf: "",
      extraInfo: "Kilo vermeye yardımcı",
      price: 750,
      img: 
        "/images/10.webp",
      ingredients: [
        "Kurt Üzümü",
        "Funda yaprağı",
        "Kitre",
        "Chia tohumu",
        "Sandaroz sakızı",
        "Defne yaprağı",
        "Kayısı aroması",
        "Yeşil çay",
        "Açlık otu",
        "Hibiskus",
        "Kekik",
        "Acı çehre",
        "Sahtere",
        "Anason",
        "Portakal",
        "Rezene"
      ],
      warnings: [
        "Serin ve rutubetsiz ortamda muhafaza ediniz.",
        "İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız.",
        "Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
      ],
      usage: {
        daily: "Günde üç defa, sabah, öğle ve akşam bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenerek tüketilir."
      },
      storage: "Serin ve kuru bir yerde, rutubetsiz ortamda muhafaza ediniz.",
      purpose: [
        "Kilo vermeye yardımcı olur.",
        "Metabolizmayı hızlandırır.",
        "Vücudu toksinlerden arındırır."
      ]
    },
    {
      _id: 31,
      productName: "Relax Çayı Sleep Tea Uyku Öncesi Bitki Çayı - Passifloralı 20'li Servis",
      ficheTech: [
        {
          label: "Bilişsel işlevi artırır, hafızayı güçlendirir ve kişiye rahatlık hissi vererek uykuyu düzenler. Sinirsel sebeplerle oluşan huzursuzluk, mide-bağırsak ağrıları ve kalp rahatsızlıklarında rahatlama sağlar."
        },
        {
          label: "Relax Çayı'nın en güzel farkı, kullanıldığı andan itibaren aynı gün içinde etkisini gösterme özelliğidir. Özellikle yoğun iş hayatının ve zorlu koşulların getirdiği psikolojik etkilere karşı mükemmel bir çözüm sunar."
        },
        {
          label: "Günün stresinden arınmak, zihinsel ve duygusal dengeyi sağlamak isteyenler için doğadan gelen bir huzur kaynağıdır. Yoğun tempolu günlerin etkilerinden korunmak ve rahatlamak için tercih edilebilir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "Enhances cognitive function, strengthens memory, and promotes relaxation for better sleep. This special tea provides relief from stress-induced discomfort, stomach-intestinal pain, and heart issues. Relax Tea shows its effects on the same day it is used, offering an excellent solution to the psychological impacts of a demanding work life and challenging conditions. Ideal for those seeking natural tranquility and mental balance.",
        tr: "Bilişsel işlevi artırır, hafızayı güçlendirir ve kişiye rahatlık hissi vererek uykuyu düzenler. Bu özel çay, sinirsel sebeplerle oluşan huzursuzluk, mide-bağırsak ağrıları ve kalp rahatsızlıklarında rahatlama sağlar. Relax Çayı'nın en güzel farkı, kullanıldığı andan itibaren aynı gün içinde etkisini gösterme özelliğidir. Özellikle yoğun iş hayatının ve zorlu koşulların getirdiği psikolojik etkilere karşı mükemmel bir çözüm sunar. Günün stresinden arınmak, zihinsel ve duygusal dengeyi sağlamak isteyenler için doğadan gelen bir huzur kaynağıdır."
      },
      cat: "Herbal Teas",
      pdf: "",
      extraInfo: "Passifloralı Relax Çayı, kafeinsizdir.",
      price: 225,
      img: 
        "/images/1.webp",
      ingredients: [
        "Passiflora",
        "Chamomile",
        "Lavender"
      ],
      warnings: [
        "Serin ve kuru bir yerde muhafaza ediniz.",
        "İçeriğindeki herhangi bir maddeye karşı alerjiniz var ise kullanmayınız.",
        "Beklenmedik bir reaksiyon gösterir ise doktorunuza başvurunuz."
      ],
      usage: {
        daily: "Günde iki defa, sabah ve akşam bir fincan sıcak suyun içerisine bir adet süzen poşet atılarak 3-4 dakika demlenir ve tüketilir."
      },
      storage: "Serin ve kuru bir yerde muhafaza ediniz.",
      purpose: [
        "Uykuyu düzenlemeye yardımcı olur.",
        "Stresi azaltır ve rahatlama sağlar.",
        "Zihinsel ve duygusal dengeyi destekler."
      ]
    },
    {
      _id: 26,
      productName: "Hamilelik Dönemi Kolaylaştırmaya Yardımcı Trabzon Hurması Sirkesi 500 ML",
      ficheTech: [
        {
          label: "Akdeniz bölgesinde yetişmiş ilaç kullanılmadan olgunlaştırılmış Trabzon hurmasından üretilmiştir."
        },
        {
          label: "Doğal fermantasyon yöntemiyle bir yıllık süre içinde üretilmiştir."
        },
        {
          label: "Trabzon hurması, Abanozgiller familyasından olup, subtropik iklim kuşaklarında yetişir."
        },
        {
          label: "Portakal renginde olan bu meyve, tanence (tanik asit) polifenolik bileşikler içerir."
        },
        {
          label: "Trabzon hurması, A vitamini, karbonhidrat, potasyum, kalsiyum ve fosfor açısından zengindir."
        }
      ],
      brand: "Scuti Natural",
      badge: false,
      des: {
        en: "This persimmon vinegar is produced from naturally matured persimmons grown in the Mediterranean region without the use of chemicals. The vinegar is made through a one-year natural fermentation process. Persimmons, also known as 'cennet hurması' (paradise fruit), belong to the Ebenaceae family and thrive in subtropical climates. Rich in vitamin A, carbohydrates, potassium, calcium, and phosphorus, this vinegar supports a healthy pregnancy and may assist in easing the childbirth process when consumed under medical supervision.",
        tr: "Bu Trabzon hurması sirkesi, Akdeniz bölgesinde ilaç kullanılmadan olgunlaştırılmış Trabzon hurmasından üretilmiştir. Sirke, bir yıllık doğal fermantasyon süreciyle yapılmıştır. Trabzon hurması, diğer adıyla 'cennet hurması', Abanozgiller familyasından olup subtropik iklimlerde yetişir. A vitamini, karbonhidrat, potasyum, kalsiyum ve fosfor açısından zengin olan bu sirke, hamilelik döneminde sağlıklı bir süreç destekleyebilir ve doktor onayı ile doğum sürecini kolaylaştırabilir."
      },
      cat: "Vinegars",
      pdf: "",
      extraInfo: "GMO-free",
      price: 350,
      img: 
        "/images/6.webp",
      ingredients: [
        "Trabzon hurması",
        "Doğal fermantasyon ile elde edilen hurma sirkesi"
      ],
      warnings: [
        "Hamileyseniz veya emziriyorsanız kullanmadan önce doktorunuza danışın.",
        "Alerjiniz varsa kullanmayınız."
      ],
      usage: {
        daily: "Hamilelik sürecini kolaylaştırmaya yardımcı olmak için günlük olarak bir çay bardağının 1/3'ü kadar Trabzon hurması sirkesini su ile karıştırarak tüketin."
      },
      storage: "Serin ve kuru bir yerde, direkt güneş ışığından uzak tutarak saklayınız.",
      purpose: [
        "Hamilelik sürecini kolaylaştırmaya yardımcı olur.",
        "Bağışıklık sistemini güçlendirir.",
        "Vücuttaki tanik asit içeriği sayesinde antioksidan özellik sağlar."
      ]
    }
  ]
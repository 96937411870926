import { cloneDeep } from "lodash";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Succes from "./Succes";
import { resetCart } from "../../redux/orebiSlice";


const Checkout = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const products = useSelector((state) => state.orebiReducer.products);
  const [succes, setSucces] = useState(false);
  const [totalAmt, setTotalAmt] = useState("");
  const [shippingCharge, setShippingCharge] = useState("");
  const [formData, setFormdata] = useState({
    name: {
      key: "name",
      placeHolder: "name",
      type: "text",
      value: "",
      error: false,
    },
    surname: {
      key: "surname",
      placeHolder: "surname",
      type: "text",
      value: "",
      error: false,
    },
    country: {
      key: "country",
      placeHolder: "country",
      type: "text",
      value: "",
      error: false,
    },
    city: {
      key: "city",
      placeHolder: "city",
      type: "text",
      value: "",
      error: false,
    },
    address: {
      key: "address",
      placeHolder: "address",
      type: "text",
      value: "",
      error: false,
    },
    phoneNumber: {
      key: "phoneNumber",
      placeHolder: "phoneNumber",
      type: "number",
      value: "",
      error: false,
    },
    email: {
      key: "email",
      placeHolder: "email",
      type: "text",
      value: "",
      error: false,
    },
  });

  useEffect(() => {
    let price = 0;
    products.map((item) => {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price);
  }, [products]);
  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(30);
    } else if (totalAmt <= 400) {
      setShippingCharge(25);
    } else if (totalAmt > 401) {
      setShippingCharge(20);
    }
  }, [totalAmt]);

  const handleChangeData = (value, fieldName) => {
    let updated = cloneDeep(formData);
    updated[fieldName].value = value;
    updated[fieldName].error = false; // Reset error on change
    setFormdata(updated);
  };

  const validateFormData = () => {
    let isValid = true;
    let updatedFormData = cloneDeep(formData);

    Object.keys(updatedFormData).forEach((key) => {
      if (!updatedFormData[key].value.trim()) {
        updatedFormData[key].error = true;
        isValid = false;
      } else {
        updatedFormData[key].error = false;
      }
    });

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(updatedFormData.email.value)) {
      updatedFormData.email.error = true;
      isValid = false;
    }

    setFormdata(updatedFormData);
    return isValid;
  };

  const handleCreatePayment = () => {
    if(validateFormData()){
    axios
      .post("http://api.scutinatural.shop/isyerim/createPaymentlink", {
        Amount: totalAmt + shippingCharge, //toplam işlem tutarı
        ReturnUrl: "www.test.com", //bir eticaret sitesi üzerinden işlem yapılıyorsa, işlem sonucunun iletileceği adres
        InstallmentActive: false, //taksit yapılıp yapılamayacağı
        SendSms: false, //müşteri telefonuna sms gönderimi için
        Description: "ecom", //işleme ait not
        Customer: {
          Name: formData.name.value, //müşteri adı
          Surname: formData.surname.value, //müşteri soyadı
          Phone: formData.phoneNumber.value, //* müşteriye ait telefon numarası
          Email: formData.email.value, //müşteri eposta
          City: formData.city.value, //müşteri ili
          Address: formData.address.value, //müşteri adresi
        },
        Products: products,
      })
      .then((res) => {
        setFormdata({
          name: {
            key: "name",
            placeHolder: "name",
            type: "text",
            value: "",
            error: false,
          },
          surname: {
            key: "surname",
            placeHolder: "surname",
            type: "text",
            value: "",
            error: false,
          },
          country: {
            key: "country",
            placeHolder: "country",
            type: "text",
            value: "",
            error: false,
          },
          city: {
            key: "city",
            placeHolder: "city",
            type: "text",
            value: "",
            error: false,
          },
          address: {
            key: "address",
            placeHolder: "address",
            type: "text",
            value: "",
            error: false,
          },
          phoneNumber: {
            key: "phoneNumber",
            placeHolder: "phoneNumber",
            type: "number",
            value: "",
            error: false,
          },
          email: {
            key: "email",
            placeHolder: "email",
            type: "text",
            value: "",
            error: false,
          },
        });
        dispatch(resetCart())
        setSucces(true);

        // console.log(res);
      })
      .catch((res) => {
        toast.error("Error occured");
      });
    }else{
      toast.error("Please fill in all fields correctly.");
    }
  };

  return (
    <>
      {!succes ? (
        <div className="checkoutContainer">
          <h1>Provide information for checkout</h1>
          <div className="checkoutForm">
            {Object.keys(formData).map((item) => (
              <div className="labelValue">
                <label>{t(`Checkout.${formData[item].key}`)}</label>
                <input
                  className={`w-44 mdl:w-52 h-8 px-4 border text-primeColor text-sm outline-none border-gray-400 ${
                    formData[item].error ? "labelError" : ""
                  }`}
                  type="text"
                  placeholder={t(`Checkout.${formData[item].placeHolder}`)}
                  value={formData[item].value}
                  onChange={(event) =>
                    handleChangeData(event.target.value, item)
                  }
                />
              </div>
            ))}
          </div>
          <p>{t("Checkout.paymentInfo")}</p>
          <button
            className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
            onClick={handleCreatePayment}
          >
            {t("Checkout.requestPaymentLink")}
          </button>
        </div>
      ) : (
        <>
          <Succes />
          <h3 class='SuccesMessage'>Payment Link succes</h3>
        </>
      )}
    </>
  );
};

export default Checkout;

import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  imprimante1,
  imprimante2,
  imprimante4,
  imprimante5,
  imprimante6,
  imprimante7,
  imprimante8,
  imprimante9,
  encre1,
  encre2,
  encre3,
  encre4,
  ruban1,
  ruban2,
  ruban3,
  ruban4,
  bac1,
  bac2,
  bac3,
  bac4,
  pdf1,
  IMPRIMANTE_PANTUM_CP2200DW,
  IMPRIMANTE_PANTUM_BM5100FDW,
  IMPRIMANTE_PANTUM_M6609N,
  IMPRIMANTE_PANTUM_P3300DN,
  IMPRIMANTE_PANTUM_P3300DW,
  IMPRIMANTE_PANTUM_P2509W,
  IMPRIMANTE_PANTUM_BP5100DN,
  IMPRIMANTE_PANTUM_M6559N,
  IMPRIMANTE_PANTUM_P2509,
  hp1,
  hp2,
  hp3,
  ricoh1,
  ricoh2,
  ricoh3,
  ricoh4,
  ricoh5,
  espson1,
  espson2,
} from "../assets/images/index";
import { products } from "./product";

import "./style.css";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  // {
  //   _id: 1005,
  //   title: "Journal",
  //   link: "/journal",
  // },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
// export const SplOfferData = [
//   {
//     _id: "201",
//     img: imprimante1,
//     productName: "imprimante",
//     price: "35.00",
//     color: "Blank and White",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "202",
//     img: imprimante2,
//     productName: "imprimante",
//     price: "180.00",
//     color: "Gray",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "203",
//     img: imprimante4,
//     productName: "imprimante",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "204",
//     img: imprimante5,
//     productName: "imprimante",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "205",
//     img: imprimante6,
//     productName: "imprimante",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "206",
//     img: imprimante7,
//     productName: "imprimante",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "207",
//     img: imprimante8,
//     productName: "imprimante",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "208",
//     img: imprimante9,
//     productName: "imprimante",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "imprimante",
//   },
//   {
//     _id: "209",
//     img: encre1,
//     productName: "encre",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "ancre",
//   },
//   {
//     _id: "210",
//     img: encre2,
//     productName: "encre",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "ancre",
//   },
//   {
//     _id: "211",
//     img: encre3,
//     productName: "encre",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "ancre",
//   },
//   {
//     _id: "212",
//     img: encre4,
//     productName: "encre",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "ancre",
//   },
//   {
//     _id: "213",
//     img: ruban1,
//     productName: "Ruban",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "214",
//     img: ruban2,
//     productName: "Ruban",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "215",
//     img: ruban3,
//     productName: "Ruban",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "216",
//     img: ruban4,
//     productName: "Ruban",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "217",
//     img: bac1,
//     productName: "Bac de dechet",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
//   {
//     _id: "219",
//     img: bac2,
//     productName: "Bac de dechet",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
//   {
//     _id: "220",
//     img: bac3,
//     productName: "Bac de dechet",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
//   {
//     _id: "221",
//     img: bac4,
//     productName: "Bac de dechet",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
// ];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

// export const paginationItems = [
//   {
//     _id: "201",
//     img: IMPRIMANTE_PANTUM_CP2200DW,
//     productName: "Imprimante PANTUM CP2200DW",
//     price: "35.00",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser PANTUM Couleur - Fonctions: Impression  - Technologie d'impression: Laser - Format Papier: A4 - Vitesse d’impression(Couleur/N&B): 24 ppm (A4) / 26 ppm (Lettre) - Résolution d'impression: 600 x 600 dpi - Sortie papier: 100 page - Mémoire: Double cœur, 1 GHz - Impression recto verso: Automatique - Heure de la première impression: Moins de 11s  - Connecteurs: USB 2.0 haut débit Ethernet 10/100/1000 BaseTX (RJ-45) 802.11b/g/n Sans fil - Dimensions: 411.2 x 394.1 x 243.7mm - Poids: 16,1 kg - Couleur: Blanc",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "Technology ", value: "Electrophotographic monochrome laser " },
//       { label: "Print speed ", value: "22 ppm (A4)/23 ppm (Letter)" },

//       { label: "First print out time ", value: "Less than 7.8s       " },
//       { label: "Maximum Monthly Duty Cycle", value: "15,000 pages " },
//       {
//         label: "Recommended monthly volume Resolution(dpi) ",
//         value: "700 pages ",
//       },
//       { label: "Printer language Duplex Mode ", value: "Max. 1,200×1,200 " },
//       { label: "Printer  ", value: "Max. 1,2   " },
//       { label: "galass  ", value: "Max. 1,2   " },
//     ],
//   },
//   {
//     _id: "202",
//     img: IMPRIMANTE_PANTUM_BM5100FDW,
//     productName: "IMPRIMANTE PANTUM BM5100FDW",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Pantum BM5100fdw Imprimante laser mono : Pantum BM5100fdw -Imprimante :laser -monochrome multifonction,:format A4-,4-en-1 avec fonctions impression, copie, numérisation et fax, vitesse d'impression de 40ppm, connexion Réseau :LAN RJ45-, Wifichrome multifonction, format A4, 4-en-1 avec fonctions impression, copie, numérisation et fax, vitesse d'impression de 40ppm, connexion Réseau LAN RJ45, Wifi",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "Vitesse d'impression ", value: "40ppm(A4)/42ppm(lettre) " },
//       { label: "Temps de sortie de la première page ", value: "≤6.9 s" },

//       {
//         label: "Nombre de pages mensuel recommandé",
//         value: "750 à 4,000 Pages",
//       },
//       { label: "Résolution d'impression", value: "Max.1,200x1,200 dpi" },
//       {
//         label: "Langage d'impression ",
//         value: "PCL5e, PCL6, PS        ",
//       },
//       { label: "Vitesse du processeur", value: "1.2 GHz" },
//       { label: "Mémoire", value: "512 MB" },
//       {
//         label: "Panneau de commande ",
//         value: "LCD 2 lignes ||  Écran tactile 3,5 pouces      ",
//       },
//       { label: "Impression recto-verso automatique      ", value: "Oui" },
//       {
//         label: "Autres fonctions d'impression      ",
//         value:
//           "Impression brochure, impression sécurisée, impression disque USB Compatible AirPrint, Mopria, APP mobile (APP iOS/App Android)",
//       },
//       { label: "Vitese de copie ", value: "40ppm(A4)/42ppm(lettre)" },
//       {
//         label: "Temps de sortie de la première page",
//         value: "Plateau : moins de 10 s ADF : moins de 11 s",
//       },
//     ],
//   },

//   {
//     _id: "203",
//     img: IMPRIMANTE_PANTUM_BP5100DN,
//     productName: "IMPRIMANTE PANTUM BP5100DN",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Monochrome Laser PANTUM BP5100DN : Fonctions Impression - :Capacité Bac à papier 250 pages - :Formats papier -: A4 - Technologie d’impression Laser Monochrome - :Vitesse d’impression Noir & Blanc Jusqu’à 40 pages par minute en A4 -: Résolution 1200 dpi -: Mémoire 512 Mo - Connectivité Ethernet, USB 2.0 -:Auto-Duplex-, Network-Ready : Impression silencieuse - faible encombrement et respectueux de l'environnement -: cartouches à haut rendement en option - processeur haute vitesse 1,2 GHz - Dimensions: 364 x 344 x 257 mm - Poids: 9.3 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "Vitesse d'impression ", value: "40ppm(A4)/42ppm(lettre) " },
//       { label: "Temps de sortie de la première page ", value: "≤6.9 s" },

//       {
//         label: "Nombre de pages mensuel recommandé",
//         value: "750 à 4,000 Pages",
//       },
//       { label: "Résolution d'impression", value: "Max.1,200x1,200 dpi" },
//       {
//         label: "Langage d'impression ",
//         value: "PCL5e, PCL6, PS        ",
//       },
//       { label: "Vitesse du processeur", value: "1.2 GHz" },
//       { label: "Mémoire", value: "512 MB" },
//       {
//         label: "Panneau de commande ",
//         value: "LCD 2 lignes ||  Écran tactile 3,5 pouces      ",
//       },
//       { label: "Impression recto-verso automatique      ", value: "Oui" },
//       {
//         label: "Autres fonctions d'impression      ",
//         value:
//           "Impression brochure, impression sécurisée, impression disque USB Compatible AirPrint, Mopria, APP mobile (APP iOS/App Android)",
//       },
//       { label: "Vitese de copie ", value: "40ppm(A4)/42ppm(lettre)" },
//       {
//         label: "Temps de sortie de la première page",
//         value: "Plateau : moins de 10 s ADF : moins de 11 s",
//       },
//     ],
//   },
//   {
//     _id: "2005",
//     img: IMPRIMANTE_PANTUM_M6609N,
//     productName: "IMPRIMANTE PANTUM M6559N",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser 3en1 à toner rechargeable PANTUM M6559N - Capacité d'impression Jusqu'à 1600 pages - Vitesse d’impression : 22 ppm (A4) / 23 ppm (Lettre) - Résolution (impression, copie et numérisation) : 1200×1200dpi - Heure de la première impression : Moins de 7.8s - Connectivité : USB 2.0 haute vitesse - Vitesse de copie : 22cpm (A4) / 23cpm (Lettre) - Capacité du chargeur automatique de documents : 35 pages - Entrée papier : 150 pages - Sortie papier : 100 pages - Dimensions : 417 x 305 x 301 mm - Poids : 8.5 kg - Garantie 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "gtin	", value: "M6559N      " },
//       { label: "Marque	", value: "PANTUM      " },
//       { label: "fonctions	", value: "Monofonction      " },
//       { label: "Technologie d impression		", value: "Laser      " },
//       { label: "Impression	", value: "Noir & Blanc      " },
//       { label: "Vitesse du Processeur		", value: "600MHz      " },
//       {
//         label: "Capacité papier		",
//         value: "Capacité standard: 1 600 pages      ",
//       },
//       { label: "Gestion d entrée Papier Standard		", value: "150 pages      " },
//       { label: "Premiére Page Imprimée		", value: "Moins de 7.8s      " },
//       { label: "Cycle d utilisation Mensuel		", value: "15 000 pages      " },
//       {
//         label: "Résolution d impression		",
//         value: "Max. 1 200 × 1 200 ppp      ",
//       },
//       {
//         label: "Résolution d'impression Noir		",
//         value: "Max. 1 200 × 1 200 ppp      ",
//       },
//       { label: "formats		", value: "A4      " },
//       { label: "Sortie papier		", value: "100 pages      " },
//       {
//         label: "Vitesse de Copie Noir Blanc		",
//         value: "22 ppm (A4) / 23 ppm (Lettre)      ",
//       },
//       { label: "Poids		", value: "4,75 kg      " },
//     ],
//   },

//   {
//     _id: "20005",
//     img: IMPRIMANTE_PANTUM_M6559N,
//     productName: "IMPRIMANTE PANTUM M6559N",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser 3en1 à toner rechargeable PANTUM M6559N - Capacité d'impression Jusqu'à 1600 pages - Vitesse d’impression : 22 ppm (A4) / 23 ppm (Lettre) - Résolution (impression, copie et numérisation) : 1200×1200dpi - Heure de la première impression : Moins de 7.8s - Connectivité : USB 2.0 haute vitesse - Vitesse de copie : 22cpm (A4) / 23cpm (Lettre) - Capacité du chargeur automatique de documents : 35 pages - Entrée papier : 150 pages - Sortie papier : 100 pages - Dimensions : 417 x 305 x 301 mm - Poids : 8.5 kg - Garantie 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "gtin	", value: "M6559N      " },
//       { label: "Marque	", value: "PANTUM      " },
//       { label: "fonctions	", value: "Monofonction      " },
//       { label: "Technologie d impression		", value: "Laser      " },
//       { label: "Impression	", value: "Noir & Blanc      " },
//       { label: "Vitesse du Processeur		", value: "600MHz      " },
//       {
//         label: "Capacité papier		",
//         value: "Capacité standard: 1 600 pages      ",
//       },
//       { label: "Gestion d entrée Papier Standard		", value: "150 pages      " },
//       { label: "Premiére Page Imprimée		", value: "Moins de 7.8s      " },
//       { label: "Cycle d utilisation Mensuel		", value: "15 000 pages      " },
//       {
//         label: "Résolution d impression		",
//         value: "Max. 1 200 × 1 200 ppp      ",
//       },
//       {
//         label: "Résolution d'impression Noir		",
//         value: "Max. 1 200 × 1 200 ppp      ",
//       },
//       { label: "formats		", value: "A4      " },
//       { label: "Sortie papier		", value: "100 pages      " },
//       {
//         label: "Vitesse de Copie Noir Blanc		",
//         value: "22 ppm (A4) / 23 ppm (Lettre)      ",
//       },
//       { label: "Poids		", value: "4,75 kg      " },
//     ],
//   },

//   {
//     _id: "206",
//     img: IMPRIMANTE_PANTUM_P3300DN,
//     productName: "IMPRIMANTE PANTUM P3300DN",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser PANTUM P3300DN - Fonctions: Impression - Technologie d'impression: Laser - Format Papier: A4-A5 - Vitesse d’impression: 33 ppm (A4) / 35 ppm (Lettre) - Résolution d'impression: Jusqu'à 1200 x 1200 ppp - Papier Bac d'alimentation: 250 pages - Sortie papier: 150 page - Mémoire: 256 Mo - Impression recto verso: Automatique - Heure de la première impression: Moins de 8.2s  - Taille du support: A4, A5, JIS B5, IS0 B5, A6, Lettre, Légal, Exécutif, Folio, Oficio, Déclaration, Carte postale japonaise, ZL, Big 16K, Big 32K, 16K, 32K, B6， Yougata4, Carte postale, Younaga3, Nagagata3, Yougata2 - Connecteurs: USB2.0 haut débit / Ethernet：IEEE 802.3 10/100Base-Tx - Dimensions: 354 x 334 x 232mm - Poids: 6,8 kg - Couleur: Blanc",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "gtin	", value: "P3300DN      " },
//       { label: "fonctions	", value: "Monofonction      " },
//       { label: "Mémoire	", value: "256MO      " },
//       { label: "Technologie d impression		", value: "laser      " },
//       { label: "Impression		", value: "Noir & Blanc      " },
//       { label: "Connectivite		", value: "Réseau      " },
//       { label: "Vitesse du Processeur		", value: "350 MHz      " },
//       { label: "Type ecran		", value: "LCD 2 lignes      " },
//       {
//         label: "Consommation d énergie		",
//         value:
//           "Impression : 525 W en moyenne Veille : 50 W en veille : moins de 2 W      ",
//       },
//       {
//         label: "Gestion d entrée Papier Standard		",
//         value: "Papier Bac d'alimentation: 250pages      ",
//       },
//       { label: "Premiére Page Imprimée		", value: "Moins de 8,2 secondes      " },
//       {
//         label: "Vitesse d impression Noir		",
//         value: "33 ppm (A4) / 35 ppm (Lettre)      ",
//       },
//       { label: "Cycle d utilisation Mensuel		", value: "60000pages      " },
//       {
//         label: "Résolution d impression		",
//         value: "1 200 x 1 200 dpi (maximum)      ",
//       },
//       { label: "formats	", value: "A4      " },
//       {
//         label: "Connecteurs		",
//         value: "USB2.0 haut débit / Ethernet：IEEE 802.3 10/100Base-Tx      ",
//       },
//       { label: "Fonctionnalités		", value: "Impression      " },
//       {
//         label: "Volume de Pages Mensuel Recommandé		",
//         value: "750 à 3 500 pages      ",
//       },
//       { label: "Dimensions		", value: "354 x 334 x 232mm      " },
//       { label: "Garantie	", value: "1ANS      " },
//     ],
//   },

//   {
//     _id: "207",
//     img: IMPRIMANTE_PANTUM_P3300DW,
//     productName: "IMPRIMANTE PANTUM P3300DW",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser PANTUM P3300DW - Fonctions: Impression - Technologie d'impression: Laser - Format Papier: A4-A5 - Vitesse d’impression: 33 ppm (A4) / 35 ppm (Lettre) - Résolution d'impression: Jusqu'à 1200 x 1200 ppp - Papier Bac d'alimentation: 250 pages - Sortie papier: 150 page - Mémoire: 256 Mo - Impression recto verso: Automatique - Heure de la première impression: Moins de 8.2s - Interface: Ethernet USB 2.0 haut débit : Wi-Fi IEEE 802.3 10/100Base-Tx : IEEE 802.11b/g/n - Connecteurs: USB ; WIFI - Dimensions: 354 x 334 x 232mm - Poids: 6,8 kg - Couleur: Blanc",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "gtin		", value: "P3300DW      " },
//       { label: "fonctions	", value: "Monofonction      " },
//       { label: "Mémoire	", value: "256 Mo      " },
//       { label: "Technologie d impression		", value: "Laser      " },
//       { label: "Connectivite	", value: "WIFI      " },
//       { label: "Vitesse du Processeur		", value: "350 MHz      " },
//       { label: "Type ecran		", value: "LCD 2 lignes      " },
//       {
//         label: "Consommation d énergie		",
//         value:
//           "Impression : 525 W en moyenne Veille : 50 W en veille : moins de 2 W      ",
//       },
//       {
//         label: "Gestion d entrée Papier Standard		",
//         value: "Papier Bac d'alimentation: 250pages      ",
//       },
//       { label: "Premiére Page Imprimée		", value: "Moins de 8,2 secondes      " },
//       {
//         label: "Vitesse d impression Noir		",
//         value: "33 ppm (A4) / 35 ppm (Lettre)      ",
//       },
//       { label: "Cycle d utilisation Mensuel		", value: "60000pages      " },
//       { label: "Résolution d impression		", value: "Max.1200x1200 dpi      " },
//       {
//         label: "Volume de Pages Mensuel Recommandé		",
//         value: "750 à 3 500 pages      ",
//       },
//       {
//         label: "Interface Réseau	",
//         value:
//           "Ethernet USB 2.0 haut débit : Wi-Fi IEEE 802.3 10/100Base-Tx : IEEE 802.11b/g/n      ",
//       },
//       {
//         label: "Contenu de L'emballage		",
//         value:
//           "Imprimante - Cordon d’alimentation - Câble d’interface USB - Plateau de sortie - CD-ROM - Guide de configuration rapide      ",
//       },
//       { label: "Garantie	", value: "1 ANS      " },
//     ],
//   },

//   {
//     _id: "208",
//     img: IMPRIMANTE_PANTUM_P2509W,
//     productName: "IMPRIMANTE PANTUM P2509W",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser PANTUM P2509W - Fonctions: Impression - Technologie d'impression: Laser - Format Papier: A4 - Vitesse d’impression: 22 ppm (A4) / 23 ppm (Lettre) - Résolution d'impression: Jusqu'à 1200 x 1200 ppp - Entrée papier: de 150 feuilles - Sortie papier: 100 page - Mémoire: 128 Mo - Impression recto verso: Manuel - Heure de la première impression: Moins de 7.8s - Taille du support: A4, A5, A6, JIS B5, ISO B5, B6, Lettre, Légal, Exécutif, Déclaration, Enveloppe Monarch, Enveloppe DL, enveloppe C5, enveloppe C6, enveloppe NO.10, carte postale japonaise, Folio, Oficio, Grand 16k, 32k, 16k, Grand 32k, ZL,Yougata4, Carte postale, Younaga3, Nagagata3, Yougata2 - Toner rechargeable - Connecteurs: USB 2.0 ; Wi-Fi - Dimensions: 337 x 220 x 178 mm - Poids: 4,75 kg - Couleur: Blanc.",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "gtin	", value: "P2509W      " },
//       { label: "fonctions	", value: "Monofonction      " },
//       { label: "Technologie d impression		", value: "Laser      " },
//       { label: "Ecran Tactile		", value: "Non      " },
//       { label: "Connectivite	", value: "WiFi      " },
//       { label: "Vitesse du Processeur		", value: "600MHz      " },
//       {
//         label: "Capacité papier		",
//         value: "Capacité standard: 1 600 pages      ",
//       },
//       { label: "Premiére Page Imprimée		", value: "Moins de 7.8s      " },
//       { label: "Cycle d utilisation Mensuel		", value: "15 000 pages      " },
//       {
//         label: "Résolution d impression		",
//         value: "Max. 1 200 × 1 200 ppp      ",
//       },
//       { label: "formats	", value: "A4      " },
//       { label: "Connecteurs		", value: "USB 2.0, Wi-Fi      " },
//       {
//         label: "Vitesse de Copie Noir Blanc		",
//         value: "22 ppm (A4) / 23 ppm (Lettre)      ",
//       },
//       {
//         label: "Contenu de L'emballage		",
//         value:
//           "Imprimante - Cordon d’alimentation - Câble d’interface USB - Plateau de sortie - CD-ROM - Guide de configuration rapide      ",
//       },
//       { label: "Dimensions		", value: "337 x 220 x 178 mm      " },
//     ],
//   },
//   {
//     _id: "233",
//     img: IMPRIMANTE_PANTUM_P2509,
//     productName: "IMPRIMANTE PANTUM P2509",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Pantum",
//     des: "Imprimante Laser PANTUM P2509 - Fonctions Impression - Capacité d'impression Jusqu'à :1600 pages - Formats papier: A4 - Technologie d’impression Laser Monochrome - Vitesse d’impression Noir & Blanc: Jusqu’à 22 pages par minute en A4 - Résolution: 1200 dpi - Mémoire: 128 Mo - Connectivité: USB 2.0 - Dimensions: 337 x 220 x 178 mm - Poids: 4.75 kg - Toner recheargeable - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [],
//   },
//   {
//     _id: "220",
//     img: bac3,
//     productName: "Bac de dechet",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
//   {
//     _id: "221",
//     img: bac4,
//     productName: "Bac de dechet",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
//   {
//     _id: "215",
//     img: ruban3,
//     productName: "Ruban",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "216",
//     img: ruban4,
//     productName: "Ruban",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "209",
//     img: encre1,
//     productName: "encre",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Encre",
//   },
//   {
//     _id: "210",
//     img: encre2,
//     productName: "encre",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Encre",
//   },

//   {
//     _id: "211",
//     img: encre3,
//     productName: "encre",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Encre",
//   },
//   {
//     _id: "212",
//     img: encre4,
//     productName: "encre",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Encre",
//   },
//   {
//     _id: "213",
//     img: ruban1,
//     productName: "Ruban",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },
//   {
//     _id: "214",
//     img: ruban2,
//     productName: "Ruban",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Ruban",
//   },

//   {
//     _id: "217",
//     img: bac1,
//     productName: "Bac de dechet",
//     price: "25.00",
//     color: "Mixed",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },
//   {
//     _id: "219",
//     img: bac2,
//     productName: "Bac de dechet",
//     price: "220.00",
//     color: "Black",
//     badge: true,
//     des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
//     cat: "Bac",
//   },

//   // =================== imprimante hp =================

//   {
//     _id: "hp1",
//     img: hp1,
//     productName:
//       "IMPRIMANTE HP JET D'ENCRE HP SMART TANK 516 COULEUR MFP 3EN1 A4 WIFI",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Hp",
//     des: "Desc : Imprimante Jet D'encre HP SMART TANK 516 3en1 Couleur 3YW70A - Fonction: Impression, Copie, Numérisation - Technologie d'impression: jet d'encre - Vitesse d'impression Noir : Jusqu'à 22 ppm - Vitesse d’impression Couleur : Jusqu'à 16 ppm - Résolution d'impression Couleur : 1200x1200dpi - Format Papier : A4 - Capacité Papier : 100 feuilles -  Cycle d'utilisation (mensuel, A4) : Jusqu'à 1000pages - Connectivité: USB 2.0 haute vitesse, Wi-Fi, Bluetooth LE -  Dimensions: 447 x 373 x 158 mm - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "3YW70A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Ecran", value: "LCD" },
//       {
//         label: "Taille de L écran cm",
//         value: "Affichage LCD 7 segments + icône 5,08 cm (2 pouces)",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Technologie d impression", value: "Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Impression", value: "Couleur" },
//       { label: "formats", value: "A4" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 22 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 16 ppm" },
//       { label: "FAX", value: "Non" },
//       { label: "Mémoire", value: "256 Mo" },
//       {
//         label: "Qualité d impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 ppp",
//       },
//       {
//         label: "Qualité d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 ppp",
//       },
//       { label: "Résolution d impression Couleur", value: "1200x1200dpi" },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Connectivite", value: "USB 2.0, WiFi" },
//       { label: "Capacité papier", value: "100 feuilles" },
//       { label: "Cycle d utilisation Mensuel", value: "Jusqu'à 1000 pages" },
//       {
//         label: "Consommation électrique",
//         value:
//           "0,12 Watts (Arrêt manuel), 3,12 Watts (Prêt), 0,75 Watt (Veille) 5",
//       },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "Imprimante HP Smart Tank 516, Bouteilles d'encre: 3 x HP GT53XL (noir), 3 x HP GT52 (cyan, magenta et jaune), Notice de précaution pour l'encre; Fiche pour le ré-emballage et réglementaire RoH-EAC; Cordon d'alimentation, Guide d'installation; Notice PT",
//       },
//       { label: "Poids", value: "5,14 kg" },
//       { label: "Dimensions", value: "447 x 373 x 158 mm" },
//       { label: "Couleur", value: "Noir et Bleu" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },

//   {
//     _id: "hp2",
//     img: hp2,
//     productName: "IMPRIMANTE HP INK TANK WIRELESS 415 ALL-IN-ONE",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Hp",
//     des: "Desc : Imprimante Multifonction Jet d'encre 3 en 1 à réservoir intégré - Impression, numérisation, copie, sans fil - Résolution d'impression: Jusqu'à 4800 x 1200 dpi - Vitesse d'impression Couleur Jusqu'à 15 ppm -Vitesse d'impression N et B Jusqu'à 18 ppm - Résolution du scanner: 1200 x 1200 dpi - Format A4 - Interface USB - Bac d'alimentation de 60 feuilles - Dimensions: 525 x 310 x 158 mm - Garantie 1 an (+ Extension de Garantie 2 Ans ) + 5 Bouteilles",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [],
//   },

//   {
//     _id: "hp3",
//     img: hp3,
//     productName: "IMPRIMANTE HP INK TANK WIRELESS 415 ALL-IN-ONE",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Hp",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },
//   // =================== imprimante ricoh =================
//   {
//     _id: "ricoh1",
//     img: ricoh1,
//     productName: "IMPRIMANTE RICOH P501",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Ricoh",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },

//   {
//     _id: "ricoh2",
//     img: ricoh2,
//     productName: "IMPRIMANTE RICOH SP3710DN",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Ricoh",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },
//   {
//     _id: "ricoh3",
//     img: ricoh3,
//     productName: "IMPRIMANTE RICOH SP4520DN",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Ricoh",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },
//   {
//     _id: "ricoh4",
//     img: ricoh4,
//     productName: "IMPRIMANTE RICOH COULEUR LASER SPC840",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Ricoh",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },
//   {
//     _id: "ricoh5",
//     img: ricoh5,
//     productName: "IMPRIMANTE RICOH SP4510DN (407313)",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Ricoh",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },

//   // =================== imprimante ricoh =================

//   {
//     _id: "espson1",
//     img: espson1,
//     productName: "IMPRIMANTE EPSON L3251 3EN1 A4 WIFI",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Epson",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },

//   {
//     _id: "espson2",
//     img: espson2,
//     productName: "IMPRIMANTE EPSON JET D'ENCRE L3156 COULEUR A4  WIFI",
//     price: "450",
//     color: "Blanc",
//     badge: true,
//     brand: "Epson",
//     des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
//     cat: "Imprimante",
//     pdf: pdf1,
//     ficheTech: [
//       { label: "DISPONIBILITÉ", value: "En stock" },
//       { label: "gtin", value: "Z4B04A" },
//       { label: "Marque", value: "HP" },
//       { label: "Destockage", value: "Non" },
//       { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
//       { label: "fonctions", value: "Multifonction" },
//       { label: "Impression", value: "Couleur" },
//       { label: "Mémoire", value: "Non" },
//       { label: "FAX", value: "Non" },
//       { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
//       { label: "Chargement de Documents", value: "Oui" },
//       { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
//       { label: "Vitesse du Processeur", value: "360 MHz" },
//       {
//         label: "Impression sans bordure",
//         value: "Oui, jusqu’à 210 x 297 mm (A4)",
//       },
//       { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
//       { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
//       {
//         label: "Résolution d'impression Noir",
//         value: "Jusqu'à 1 200 x 1 200 DPI",
//       },
//       {
//         label: "Résolution d impression Couleur",
//         value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       {
//         label: "Résolution d impression",
//         value:
//           "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
//       },
//       { label: "Type de Scanner", value: "Scanner à Plat" },
//       { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
//       { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
//       {
//         label: "Résolution de numérisation optimisée",
//         value: "Jusqu’à 1200 ppp",
//       },
//       { label: "Recto/Verso", value: "Manuel" },
//       { label: "Connectivite", value: "USB" },
//       { label: "formats", value: "A4" },
//       {
//         label: "Premiére Page Imprimée",
//         value:
//           "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
//       },
//       {
//         label: "Alimentation-courant",
//         value:
//           "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
//       },
//       {
//         label: "Type de Papier pris en charge",
//         value:
//           "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
//       },
//       {
//         label: "Consommation électrique",
//         value:
//           "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
//       },
//       {
//         label: "Cycle d utilisation Mensuel",
//         value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
//       },
//       {
//         label: "Alimentation papier standard",
//         value:
//           "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
//       },
//       { label: "Ecran Tactile", value: "Non" },
//       { label: "Type ecran", value: "7 segments et icône LCD" },
//       {
//         label: "Volume de Pages Mensuel Recommandé",
//         value: "400 à 800 (Jusqu'à 1000 pages)",
//       },
//       { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
//       { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
//       { label: "Grammage", value: "75 g/m²" },
//       {
//         label: "Contenu de L'emballage",
//         value:
//           "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
//       },
//       { label: "Poids", value: "4,67 kg" },
//       { label: "Couleur", value: "Noir" },
//       { label: "Dimensions", value: "525 x 310 x 158 mm" },
//       { label: "Garantie", value: "1 an" },
//     ],
//   },
// ];

export const scutiProduct = products;

export const dailyProducts = [
  {
    _id: 1,
    productName: "Collagen Gummies",
    ficheTech: [
      { label: "Helps speed up and normalize the digestion process" },
      {
        label:
          "Assists in healthy weight loss without a negative impact on your health",
      },
      { label: "Helps speed up and normalize the digestion process" },
      {
        label:
          "Assists in healthy weight loss without a negative impact on your health",
      },
      { label: "Increases the reserves of energy in your body" },
      { label: "Safe with no side effects" },
      { label: "Controls blood sugar levels" },
      { label: "Help keep your muscles lean" },
      { label: "Helps improve cognitive functions" },
    ],
    brand: "Other",
    badge: false,
    des: { en: "" },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 1360,
    img: "/images/Daily-Herbals-Collagen.jpeg",
  },
  {
    _id: 2,
    productName: "Cleanse Pro",
    ficheTech: [
      { label: "Helps speed up and normalize the digestion process" },
      {
        label:
          "Assists in healthy weight loss without a negative impact on your health",
      },
      { label: "Helps speed up and normalize the digestion process" },
      {
        label:
          "Assists in healthy weight loss without a negative impact on your health",
      },
      { label: "Increases the reserves of energy in your body" },
      { label: "Safe with no side effects" },
      { label: "Controls blood sugar levels" },
      { label: "Help keep your muscles lean" },
      { label: "Helps improve cognitive functions" },
    ],
    brand: "Other",
    badge: false,
    des: {
      en: "Ingredients: Flaxseed 400mg (40%), Oat Bran 300mg (30%), Papya Leaf Extract 70mg (7%), Black Walnut Hull 30mg (3%), Prune Extract 100mg (10%), Aloe Vera 50mg (5%), L. Acidophilus 10mg (1%), Apple Pectin 40mg (4%), blended 1000mg.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 1700,
    img: "/images/Daily-Herbals-Cleanse.jpeg",
  },
  {
    _id: 3,
    productName: "GenBrain",
    ficheTech: [],
    brand: "Other",
    badge: false,
    des: { en: "" },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 1020,
    img: "/images/Daily-Gerbals-GenBrain.jpeg",
  },
  {
    _id: 4,
    productName: "Skincell Advanced",
    ficheTech: [],
    brand: "Other",
    badge: false,
    des: {
      en: "Ingredients: Water 158mg (15.8%), Cetearyl Alcohol 165mg (16.5%), Caprylic/Capric Triglycerides 67mg (6.7%), Glycerin 61mg (6.1%), Ceteareth 58mg (5.8%), Butyrospermum Parkii Butter 55mg (5.5%), Butylene Glycol 51mg (5.1%), Palmitoyl Oligopeptide 48mg (4.8%), Palmitoyl Tetrapeptide 46mg (4.6%), Octyldodecanol 41mg (4.1%), Ethyhexyl Palmitate 33mg (3.3%), Zea Mays Oil 31mg (3.1%), Tocopheryl Acetate 31mg (3.1%), Cholecalciferol 28mg (2.8%), Retinyl Palmitate 20mg (2%), Ascorbic Acid 19mg (1.9%), Pyridoxine HCl 15mg (1.5%), Silica 15mg (1.5%), Sodium Propoxyhydroxypropyl Thiosulfate Silica 14mg (1.4%), Carbomer 10mg",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 680,
    img: "/images/Daily-Herbals-Skincell-Advanced.jpeg",
  },
  {
    _id: 5,
    productName: "CANADIAN OATS N' PROTEIN",
    ficheTech: [],
    brand: "Other",
    badge: false,
    des: {
      en: "Oats n’ Protein was designed for the exact demands of on-the-go individuals who require a healthy dose of the highest quality (gluten-free) carbohydrates and high protein to stay full, focused, and fueled. Our convenient single serving cups are the ideal post-workout snack or commuter friendly breakfast, while our multi-serving 500g bags are the perfect balanced and customizable start to the day!",
    },
    cat: "Proteins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 800,
    img: "/images/quickOats.png",
  },
  {
    _id: 6,
    productName: "TRANS4ORM FAT BURNER POWDER",
    ficheTech: [],
    brand: "Other",
    badge: false,
    des: {
      en: "TRANS4ORM® is a scientifically-developed multi-stage thermogenic energizer and weight loss support supplement that works with your body to transform fat and build lean muscle.When reaching your weight loss goals, it is important to give your body all the tools it needs to support a faster metabolism, control appetite, with the energy, focus, and mood you need to perform at your best.Uncompromising quality standards backed up by researched ingredients, transparent formulas, and GMP certified.",
    },
    cat: "Proteins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 680,
    img: "/images/transform.png",
  },
  {
    _id: 7,
    productName: "R1 CHARGED CREATINE SNOW CONE",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "R1 Charged Creatine’s refreshing fruit flavors are built to support an enhanced workout with hydration, energy, and greater training capacity.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 850,
    img: "/images/creatin.png",
  },
  {
    _id: 8,
    productName: "SPORT COMPLEX “RULE ONE PROTEINS",
    ficheTech: [],
    discount: 4420,
    brand: "Other",
    badge: false,
    des: {
      en: "Better output means we obsess over the input. That is why Rule 1 Proteins™ maintains tight control over the entire process. From formulation and ingredients sourcing to blending and packaging. Everything is done the right way in our US – based, state-of-the-art facility. Streamlining and tightly controlling every phase of the process enables us to offer premium products without the premium price.",
    },
    cat: "Proteins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 5100,
    img: "/images/sportComplex.png",
  },
  {
    _id: 9,
    productName: "ALL 9 AMINO BCAAS & EAAS ORANGE CRANBERRY",
    ficheTech: [],
    brand: "Other",
    badge: false,
    des: {
      en: "With 10 grams total Essential Amino Acids (EAAs), including 7.2 grams of Branched Chain Amino Acids (BCAAs), ALL9 AminoTM is the ultimate goal-crushing muscle recovery formula. Experience the unrivaled and potent Essential Amino Acid formula in ALL9 that supports speedy muscle recovery and the Muscle Protein Synthesis process that is necessary for muscle growth.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 2040,
    img: "/images/amino.png",
  },
  {
    _id: 10,
    productName: "BCAA LEAN ENERGY BLUE RAZ",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "BCAA LEAN ENERGY is our most complete lean body performance fuel for anytime use with 5g of the highest quality BCAAs per serving combined with CLA, Acetyl-L-Carnitine and Maca Powder for fat burning and Beta-Alanine, L-Alanine and Taurine and to enhance endurance, recovery and focus. BCAA LEAN ENERGY uses natural energizers and antioxidants from Green Coffee and Green Tea for energy and focus. And if you’re on a low-carb or Keto diet, BCAA LEAN ENERGY can help support fat utilization and help fight fatigue often felt when in a calorie deficit. Make BCAA LEAN ENERGY your fuel for anytime energy, fat burning and performance.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 510,
    img: "/images/bcaa.png",
  },
  {
    _id: 11,
    productName: "SPORT COMPLEX “MASS POWER”",
    discount: 5100,
    ficheTech: [],
    brand: "Other",
    badge: false,
    des: {
      en: "The Mass Power Sport Complex offers a comprehensive solution to enhance muscle development and support weight gain. With its protein-rich components and convenient protein bars, it provides a convenient and effective way to fuel your body for optimal performance and size gains.",
    },
    cat: "Proteins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 5500,
    img: "/images/masspower.png",
  },
  {
    _id: 12,
    productName: "ISO100 HYDROLYZED WHEY",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "ISO100 is simply muscle-building fuel. If your goal is gaining muscle size and strength, then ISO100 is your perfect workout partner. Loaded with muscle-building amino acids, ISO100 can support even the most serious resistance-training programs.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 2817.5,
    img: "/images/iso100.png",
  },
  {
    _id: 13,
    productName: "CLEAN MEAL SNICKERDOODLE",
    ficheTech: [],
    brand: "Other",
    badge: true,
    des: {
      en: "Never miss a meal again. Clean Meal™ is a quick and healthy meal replacement for people on the move. Scoop, shake, and, in just 60 seconds, you’ve got a complete meal packed with wholesome, whole-food nutrition: slow-burning carbs from Swedish oats and organic quinoa; organic coconut MCT oil; 21 vitamins and minerals from organic vegetables; and non-GMO whey protein isolate treated with ioWhey® technology for up to 123% better amino acid absorption*. Rich, creamy, and satisfying, reach for Clean Meal™ to replace up to two meals per day, or anytime you need a complete, nourishing meal on the go.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 1666.5,
    img: "/images/cleanmeal.png",
  },
  {
    _id: 14,
    productName: "Solgar 7",
    ficheTech: [],
    brand: "Solgar",
    badge: false,
    des: {
      en: "Solgar brings you the next generation in joint health. Introducing Solgar ®  7 Vegetable Capsules. This unique formulation targets multiple pathways to balance the release of joint enzymes, structurally support collagen building blocks and sensitive joint cartilage, while simultaneously supporting the body's range of natural motion and flexibility. The Vitamin C in this product helps collagen formation for the normal function of cartilage and bones. It also helps to protect cells from oxidative damage (stress). Suggested Use As a food supplement for adults, take one (1) vegetable capsule daily or as directed by a healthcare practitioner. Do not exceed directions for use. If you are pregnant, nursing, taking any medications or have any medical condition, please consult your healthcare practitioner before taking this product.",

      tr: "Solgar size ortak sağlıkta yeni nesli getiriyor. Solgar ®  7 Bitkisel Kapsüllerle Tanışın.Bu eşsiz formülasyon, eklem enzimlerinin salınımını dengelemek, kolajen yapı taşlarını ve hassas eklem kıkırdağını yapısal olarak desteklemek ve aynı zamanda vücudun doğal hareket ve esneklik aralığını desteklemek için birden fazla yolu hedefler.Bu üründeki C Vitamini, kıkırdak ve kemiklerin normal fonksiyonu için kolajen oluşumuna yardımcı olur. Ayrıca hücrelerin oksidatif hasardan (stres) korunmasına da yardımcı olur. Önerilen Kullanım Yetişkinler için gıda takviyesi olarak günde bir (1) bitkisel kapsül veya bir sağlık uygulayıcısının önerdiği şekilde alın. Kullanım talimatlarını aşmayın.Hamileyseniz, emziriyorsanız, herhangi bir ilaç kullanıyorsanız veya herhangi bir sağlık sorununuz varsa, lütfen bu ürünü almadan önce sağlık uzmanınıza danışın.",
    },

    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 2788,
    img: "/images/solgar7.jpg",
  },
  {
    _id: 15,
    productName: "Nature Plus Acerola-C 500mg",
    ficheTech: [],
    brand: "Nature plus",
    badge: false,
    des: {
      en: "Never miss a meal again. Clean Meal™ is a quick and healthy meal replacement for people on the move. Scoop, shake, and, in just 60 seconds, you’ve got a complete meal packed with wholesome, whole-food nutrition: slow-burning carbs from Swedish oats and organic quinoa; organic coconut MCT oil; 21 vitamins and minerals from organic vegetables; and non-GMO whey protein isolate treated with ioWhey® technology for up to 123% better amino acid absorption*. Rich, creamy, and satisfying, reach for Clean Meal™ to replace up to two meals per day, or anytime you need a complete, nourishing meal on the go.",

      tr: "C vitamini dünyada en çok bilinen ve en çok saygı duyulan vitamindir: Bu antioksidan, damar sistemi ve doğal savunmalar da dahil olmak üzere vücudun birçok sistemini etkiler. Nature's plus Acerola-C, Acerola'nın sağlıklı ve lezzetli özelliklerini limon, kuşburnu, siyah kuş üzümü, yeşil biber ve Saphora Japonica'dan oluşan sinerjik besinlerle birleştirerek benzersiz bir C Vitamini takviyesi sağlar. Limon Bioflavoind kompleksi (Narenciye limon meyvesinden) 100mg, Kuşburnu ekstresi (rosa canina meyvesinden) 25mg, Siyah güncel meyve konsantresi 25mg, Yeşil biber özü (Capsicum annuum meyvesinden) 25mg, Rutin (Saphora japonica yaprağından) 5mg, Fruktoz , Doğal tatlar , Magnezyum stearat",
    },
    cat: "Vitamins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 430,
    img: "/images/acerolac.jpg",
  },
  {
    _id: 16,
    productName: "Nature Plus Cal/Mag/Vit D3",
    ficheTech: [],
    brand: "Nature plus",
    badge: false,
    des: {
      en: "Strong bones are the foundation of good health! High potency Nature´s Plus Cal/Mag/Vit D3 with Vitamin K2, in delicious chocolate or vanilla chewables, is an advanced formulation, employing the most effective and bioavailable bone nutrients available. A potent combination of calcium and magnesium form the basis of skeletal structure. But only Nature´s Plus Cal/Mag/Vit D3 with K2 comes supercharged with 1000 IU of Vitamin D3 and 100 mcg of K2 (menatetrenone), the most effective form of Vitamin K, for superior absorption and deposition of essential bone minerals. For complete skeletal support and an overall feeling of health, well-being and vitality, choose Nature´s Plus Cal/Mag/Vit D3 with K2! Nature´s Plus Cal/Mag/Vit D3 with Vitamin K2 conforms to the strictest standards for purity.",
      tr: "Güçlü kemikler sağlığın temelidir! Yüksek etkili Nature's Plus Cal/Mag/Vit D3 ve K2 Vitamini, lezzetli çikolata veya vanilyalı çiğnenebilir ürünlerde, mevcut en etkili ve biyoyararlı kemik besinlerini kullanan gelişmiş bir formülasyondur. Güçlü bir kalsiyum ve magnezyum kombinasyonu iskelet yapısının temelini oluşturur. Ancak yalnızca Nature's Plus Cal/Mag/Vit D3 with K2, temel kemik minerallerinin üstün emilimi ve birikmesi için 1000 IU Vitamin D3 ve 100 mcg K2 (menatetrenon) ile güçlendirilmiş olarak gelir; K Vitamininin en etkili formudur. Tam iskelet desteği ve genel sağlık, esenlik ve canlılık hissi için Nature's Plus Cal/Mag/Vit D3 with K2'yi seçin! Nature's Plus Cal/Mag/Vit D3 ve K2 Vitamini, saflık açısından en katı standartlara uygundur.",
    },
    cat: "Vitamins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 410,
    img: "/images/NATURECAL-MAG.jpg",
  },
  {
    _id: 17,
    productName: "Nature's Plus Source of Life Garden Vitamin K2",
    ficheTech: [],
    brand: "Nature plus",
    badge: false,
    des: {
      en: "Organic Gold Standard Whole Food K2 (m7) GROWN FROM CERTIFIED ORGANIC WHOLE FOODS. Source of Life Garden™ Vitamin K2 Caps with Organic Gold Standard Nutrients is the first vitamin K2 supplement made to exacting organic standards. Each easy-to-swallow capsule provides 120 mcg of vitamin K2, the most bioavailable form of this critical bone-health nutrient, as menaquinone-7 from organic natto (a fermented soy food).",
      tr: "Organic Gold Standard Tam Gıda K2 (m7) SERTİFİKALI ORGANİK BÜTÜN GIDALARDAN YETİŞTİRİLMİŞTİR. Organik Altın Standart Besin Maddelerine sahip Life Garden™ Vitamin K2 Kapakları, sıkı organik standartlara uygun olarak üretilen ilk K2 vitamini takviyesidir. Kolayca yutulabilen her bir kapsül, organik natto'dan (fermente bir soya gıdası) elde edilen menakinon-7 olarak, bu kritik kemik sağlığı besin maddesinin biyoyararlanımı en yüksek formu olan 120 mcg K2 vitamini sağlar.",
    },
    cat: "Vitamins",
    pdf: "",
    extraInfo: "GMO-free",
    price: 400,
    img: "/images/sourceoflife.jpg",
  },
  {
    _id: 18,
    productName: "Pukka Chlorella Tablet",
    ficheTech: [],
    brand: "Pukka",
    badge: false,
    des: {
      en: "Chlorella has an amazing balance of nutrients that cleanse , alkalise and protect the body with a special focus on supporting immunity. It´s ability to capture natures energy gives it a unique nutritional profile including a high chlorophyll content which is useful for cleansing. it is also high in chlorella growth factor, a special compound know to support the immune system. It is one of the most nutritionally dense foods in the world filled with antioxidants, amino acids, proteins, essential fatty acids, vitamins, minerals and nucleic acids. 100% organic, ethically sourced, suitable for vegetarian and vegans.",
      tr: "Chlorella, bağışıklığı desteklemeye özel olarak odaklanarak vücudu temizleyen, alkalileştiren ve koruyan inanılmaz bir besin dengesine sahiptir.Doğanın enerjisini yakalama yeteneği ona, temizlik için yararlı olan yüksek klorofil içeriği de dahil olmak üzere benzersiz bir beslenme profili kazandırır. aynı zamanda bağışıklık sistemini desteklediği bilinen özel bir bileşik olan chlorella büyüme faktörü açısından da yüksektir. Antioksidanlar, amino asitler, proteinler, esansiyel yağ asitleri, vitaminler, mineraller ve nükleik asitlerle dolu, dünyadaki besin açısından en yoğun gıdalardan biridir. %100 organik, etik kaynaklı, vejetaryen ve veganlar için uygundur.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 550,
    img: "/images/chlorella.jpg",
  },
  {
    _id: 19,
    productName: "Pukka Vitalise",
    ficheTech: [],
    brand: "Pukka",
    badge: false,
    des: {
      en: "Vitalise is a vibrant nutritional blend containing 30 organic ingredients including concentrated extracts from 10 herbs and spices, 7 fruits, 2 vegetables, 3 seaweeds, 2 mushrooms, 2 freshwater plants, 1 grass juice and magical flower pollen. Made with some of nature’s finest herbs, grown in fertile organic soils, it captures the incredible essence of nature’s bounty. What does Vitalise do? Vitalise combined with a balanced and varied diet, helps you to maintain energy levels, deal with the stresses of modern life, while helping you live life to the full. This broad-spectrum formula contains the highest organic grade, sustainably cultivated fruits and herbs, carefully selected to bring you the full potential of nature’s goodness. Ingredients 100% organic agricultural ingredients: Beetroot juice crystals 13%, Amla fruit powder, Slippery Elm bark, Wheat Grass juice powder 30:1, Carrot juice crystals Acerola fruit concentrate, Flower pollen, Spirulina, Licorice root, Peppermint leaf, Bilberry fruit fresh freeze dried 8:1 4%, Chlorella cracked-cell, Amla fruit juice freeze dried crystals 20:1, Pomegranate fruit juice freeze dried crystals, Seagreens® Arctic fresh seaweed blend, Cordyceps full-spectrum, Reishi full-spectrum, Cranberry fruit fresh freeze dried 10:1, Turmeric root wholistic extract, Holy Basil leaf wholistic extract, Ginger root wholistic extract, Cinnamon bark wholistic extract, Red Ginseng root 1%, Vitamin B Complex Orgen-B’s™ (Holy Basil, Lemon and Guava fruit extracts), Aloe Vera juice concentrate 200:1, Green tea leaf aqueous extract.",
      tr: "Vitalise, 10 bitki ve baharat, 7 meyve, 2 sebze, 3 deniz yosunu, 2 mantar, 2 tatlı su bitkisi, 1 çim suyu ve sihirli çiçek poleninden elde edilen konsantre özler dahil 30 organik içerik içeren canlı bir besin karışımıdır. Verimli organik topraklarda yetişen, doğanın en iyi bitkilerinden bazılarıyla yapılan bu ürün, doğanın cömertliğinin inanılmaz özünü yakalar.Vitalise ne işe yarar? Dengeli ve çeşitli bir beslenmeyle birleştirilen Vitalise, enerji seviyenizi korumanıza, modern yaşamın stresleriyle başa çıkmanıza ve hayatı dolu dolu yaşamanıza yardımcı olur. Bu geniş spektrumlu formül, size doğanın iyiliğinin tam potansiyelini sunmak için özenle seçilmiş, en yüksek organik sınıfı, sürdürülebilir şekilde yetiştirilen meyveleri ve bitkileri içerir. İçindekiler %100 organik tarımsal içerikler: Pancar suyu kristalleri %13, Amla meyve tozu, Kaygan Karaağaç kabuğu, Buğday Çimi suyu tozu 30:1, Havuç suyu kristalleri Acerola meyve konsantresi, Çiçek poleni, Spirulina, Meyan kökü, Nane yaprağı, Yabanmersini meyvesi taze dondurma kurutulmuş 8:1 %4, Chlorella çatlak hücreli, Amla meyve suyu dondurularak kurutulmuş kristaller 20:1, Nar meyve suyu dondurularak kurutulmuş kristaller, Seagreens® Arctic taze deniz yosunu karışımı, Cordyceps tam spektrumlu, Reishi tam spektrumlu, Kızılcık meyvesi taze dondurulmuş kurutulmuş 10:1, Zerdeçal kökü bütünsel özü, Kutsal Fesleğen yaprağı bütünsel özü, Zencefil kökü bütünsel özü, Tarçın kabuğu bütünsel özü, Kırmızı Ginseng kökü %1, B Vitamini Kompleksi Orgen-B's™ (Kutsal Fesleğen, Limon ve Guava meyve özleri), Aloe Vera suyu konsantresi 200:1, Yeşil çay yaprağı sulu ekstraktı.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 710,
    img: "/images/vitalise.jpg",
  },
  {
    _id: 20,
    productName: "Solaray AlphaActin",
    ficheTech: [],
    brand: "Solaray",
    badge: false,
    des: {
      en: "Supplementation of Probiotics helps us to replenish the good bacteria that live in the gut, which may deplete due to stress, use of antibiotics, high oestrogen levels, yeast infection or poor digestion.The enteric-coated capsule provides assurance that the contents will survive the acidic environment of the stomach and break down in the small intestine. Proven Activity Without Refrigeration. Directions: Take 1 capsule daily with a meal or glass of water. Ingredients: L. acidophilus (La-5), B. lactis (Bificum), Capsule (Gelatine), Dextrose, Magnesium Stearate (Vegetable Source). Allergen Advice: Contains Milk. Caution: Keep out of reach of children. Women who are pregnant or planning pregnancy should consult their doctor befor taking food supplements. Do not exceed recommended daily intake.",
      tr: "Probiyotik takviyesi, bağırsakta yaşayan ve stres, antibiyotik kullanımı, yüksek östrojen seviyeleri, maya enfeksiyonu veya zayıf sindirim nedeniyle tükenebilen iyi bakterileri yenilememize yardımcı olur. Enterik kaplı kapsül, içeriğin midenin asidik ortamında hayatta kalacağına ve ince bağırsakta parçalanacağına dair güvence sağlar. Soğutma Olmadan Kanıtlanmış Etkinlik. Talimatlar: Günde 1 kapsül yemek veya bir bardak su ile alın. İçindekiler: L. acidophilus (La-5), B. lactis (Bificum), Kapsül (Jelatin), Dekstroz, Magnezyum Stearat (Bitkisel Kaynak). Alerjen Tavsiyesi:  Süt İçerir. Dikkat: Çocukların ulaşamayacağı yerde saklayın. Hamile olan veya hamilelik planlayan kadınların gıda takviyesi almadan önce doktorlarına danışmaları gerekmektedir. Önerilen günlük alım miktarını aşmayın.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 765,
    img: "/images/acidopl.jpg",
  },
  {
    _id: 21,
    productName: "Solaray CranActin",
    ficheTech: [],
    brand: "Solaray",
    badge: false,
    des: {
      en: "A unique extract for relieving the symptoms of urinary tract infections with a guaranteed anti-adherence factor assuring that bacteria will not stick to the urethra causing re infection. Cranberry stimulates the production of Hippuric acid which also has an antibiotic effect on the urinary tract. Directions: Take 1 capsule with water, 2 to 4 times throughout the day Warnings: Keep out of reach of children. Women who are pregnant or planning pregnancy should consult their doctor before taking food supplements. Do not exceed recommended daily intake. Ingredients: Cranberry, Hydroxypropylmethyl Cellulose Capsule, Ascorbic Acid, Magnesium Hydroxide, Magnesium Oxide, Magnesium Stearate (Vegetable Source), Silica, Vegetable Juice Concentrate, Allergen Free.",
      tr: "Bakterilerin üretraya yapışıp yeniden enfeksiyona neden olmamasını garantileyen, garantili bir yapışma önleme faktörü ile idrar yolu enfeksiyonlarının semptomlarını hafifletmeye yönelik benzersiz bir ekstrakt. Kızılcık idrar yolu üzerinde antibiyotik etkisi de olan Hippurik asit üretimini uyarır. Talimatlar: Gün boyunca 2 ila 4 kez su ile 1 kapsül alın. Uyarılar: Çocukların ulaşamayacağı yerde saklayın. Hamile olan veya hamilelik planlayan kadınların gıda takviyesi almadan önce doktorlarına danışmaları gerekmektedir. Önerilen günlük alım miktarını aşmayın. İçindekiler: Kızılcık, Hidroksipropilmetil Selüloz Kapsülü, Askorbik Asit, Magnezyum Hidroksit, Magnezyum Oksit, Magnezyum Stearat (Bitkisel Kaynak), Silika, Sebze Suyu Konsantresi, Alerjen içermez.",
    },
    cat: "",
    pdf: "",
    extraInfo: "GMO-free",
    price: 493,
    img: "/images/cranactin.jpg",
  },
  {
    _id: 22,
    productName: "Pukka Turmeric",
    ficheTech: [],
    brand: "Pukka",
    badge: false,
    des: {
      en: "100% organically grown and ethically sourced.Ingredients:Turmeric root (40%), Suoi Gang green tea (20%), licorice root.cardamon pod, lemon Vana tulsi leaf.lemon essential oil flavour (6%).lemon whole (6%)",
      tr: "%100 organik olarak yetiştirilmiş ve etik kaynaklıdır.İçindekiler:Zerdeçal kökü (%40), Suoi Gang yeşil çayı (%20), meyan kökü.kakule kabuğu, limon Vana tulsi yaprağı.limon esansiyel yağı aroması (%6).Limon bütün (6%)",
    },
    cat: "Herbal Teas",
    pdf: "",
    extraInfo: "GMO-free",
    price: 20,
    img: "/images/turmeric.jpg",
  },
  {
    _id: 23,
    productName: "Pukka Clean",
    ficheTech: [],
    brand: "Pukka",
    badge: false,
    des: {
      en: "Clean Greens is a blend of super-rich green foods containing grass juice, leaf, vegetables, seed sprouts, sea greens, freshwater plants and digestive herbs.Made with some of nature's finest herbs, grown in fertile organic soils it captures the incredible essence of nature's bounty.What does Clean Greens doClean Greens naturally support the body's ability to cope better with our polluted environment and supplement our diet.This broad-spectrum formula contains the highest organic grade, sustainably cultivated fruits and herbs, carefully selected to bring you the full potential of nature’s goodness",
      tr: "Clean Greens, çimen suyu, yaprak, sebze, tohum filizleri, deniz yeşillikleri, tatlı su bitkileri ve sindirime yardımcı otlar içeren süper zengin yeşil gıdaların bir karışımıdır.Doğanın en iyi otlarından bazılarıyla yapılan, verimli organik topraklarda yetiştirilen bu ürün, doğanın cömertliğinin inanılmaz özünü yansıtıyor.Clean Greens ne işe yarar?Clean Greens, vücudumuzun kirli çevreyle daha iyi başa çıkma yeteneğini doğal olarak destekler ve beslenmemizi destekler.Bu geniş spektrumlu formül, doğanın tüm iyiliğinden faydalanmanızı sağlamak için özenle seçilmiş, en yüksek organik kalitede, sürdürülebilir şekilde yetiştirilmiş meyve ve otları içerir.",
    },
    cat: "Herbal Teas",
    pdf: "",
    extraInfo: "GMO-free",
    price: 50,
    img: "/images/clean-greens.png",
  },
];

export const SplOfferData = [...dailyProducts, ...scutiProduct];

export const paginationItems = [...dailyProducts, ...scutiProduct];
